import { useMemo } from "react";
import { View } from "react-native";
import Animated from "react-native-reanimated";
import useAnimatedOpacityStyle from "src/hooks/useAnimatedOpacityStyle";
import useStyle from "src/hooks/useStyle";
import { IconComponent } from "src/icons";
import IconCheck from "src/icons/IconCheck";
import { color } from "src/styles";
import { paddingMedium, paddingSmall } from "src/styles/spacing";
import { LabeledTextInputProps } from "../types";

const ICON_SIZE = 20;

const ValidIcon = ({
	show,
	Icon,
	variant,
}: {
	show: boolean;
	Icon: IconComponent;
	variant: "Success" | "Invalid";
}) => {
	const { theme } = useStyle();
	const animatedStyle = useAnimatedOpacityStyle(+show, {
		duration: 100,
	});

	return (
		<Animated.View
			style={[
				{
					position: "absolute",
				},
				animatedStyle,
			]}
		>
			<Icon
				color={color.Background[theme]}
				size={ICON_SIZE}
				style={{
					padding: paddingSmall,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: variant === "Success" ? color.Text[theme] : color.Error[theme],
					borderRadius: ICON_SIZE,
				}}
			/>
		</Animated.View>
	);
};

const ValidCheck = ({ value, validator }: Pick<LabeledTextInputProps, "value" | "validator">) => {
	const validState = useMemo(() => {
		if (!value) {
			return "Unknown";
		}
		if (validator && value) {
			if (validator(value)) {
				return "Valid";
			}
			return "Invalid";
		}
		return "Unknown";
	}, [value, validator]);

	return (
		<View
			style={{
				width: ICON_SIZE,
				height: ICON_SIZE,
				marginLeft: paddingMedium,
			}}
		>
			<ValidIcon show={validState === "Valid"} Icon={IconCheck} variant="Success" />
			{/*<ValidIcon
				show={validState === "Invalid"}
				Icon={IconClose}
				variant="Invalid"
        />*/}
		</View>
	);
};
export default ValidCheck;
