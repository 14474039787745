import { Text, View } from "react-native";
import { color } from "src/styles";

const Fatal = () => {
	return (
		<View
			style={{
				justifyContent: "center",
				alignItems: "center",
				flex: 1,
			}}
		>
			<Text
				style={{
					color: color.Error["Light"],
					fontSize: 20,
				}}
			>
				Fatal Error
			</Text>
			<Text>
				This error is so uncommon the devs {`didn't`} even take the time to style this
				properly.
			</Text>
			<Text>
				They have been alerted and are now panicking to figure out how this bug made it to
				production.
			</Text>
			<Text>Please try again later :)</Text>
		</View>
	);
};
export default Fatal;
