import { FieldFunctionOptions, InMemoryCache } from "@apollo/client";
import GetUserMinimalDocument from "src/api/graphql/__generated__/documents/GetUserMinimalDocument";
import UserFragmentFragmentDoc from "src/api/graphql/__generated__/documents/UserFragmentFragmentDoc";
import {
	CursorUsers,
	GetClientProfileMinimalQuery,
	SignedUrl,
	Update,
	User,
} from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import cacheMergeUsers from "./cache/(Generic)/users";
import cacheCommunityGatherings from "./cache/Community/gatherings";
import cacheGatheringHiddenPhotos from "./cache/Gathering/hiddenPhotos";
import cacheGatheringPhotos from "./cache/Gathering/photos";
import cacheGatheringTopPhotos from "./cache/Gathering/topPhotos";
import cacheMergeReactionUsers from "./cache/Photo/reactionUsers";
import cacheQueryGetCommunities from "./cache/Query/getCommunities";
import cacheQueryGetGatherings from "./cache/Query/getGatherings";
import { biDirectionalGatheringRefCodeLookup } from "./gatheringLookup";

export const getClientCache = () => {
	const cache = new InMemoryCache({
		typePolicies: {
			User: {
				keyFields: ["userId"],
				merge: (existing, incoming, { mergeObjects }) => {
					const merged = mergeObjects(mergeObjects(existing, incoming), {
						_receivedAt: getCurTime_S(),
					});

					return merged;
				},
				fields: {
					instagramUsername: {
						read: (existing: string | null) => {
							return existing?.toLowerCase() ?? null;
						},
					},
				},
			},
			Update: {
				merge: (_, incoming) => {
					return {
						...incoming,
						receivedAt: getCurTime_S(),
					} satisfies Update;
				},
			},
			SignedUrl: {
				merge: true,
			},
			PhotoTag: {
				merge: false,
			},
			Gathering: {
				keyFields: ["gatheringId"],
				merge: (existing, incoming, { readField, mergeObjects }) => {
					const refCode = readField<string>("refCode", incoming);
					const gatheringId = readField<string>("gatheringId", incoming);
					if (refCode && gatheringId) {
						biDirectionalGatheringRefCodeLookup[refCode] = gatheringId;
						biDirectionalGatheringRefCodeLookup[gatheringId] = refCode;
					}

					return mergeObjects(existing, incoming);
				},
				fields: {
					photos: cacheGatheringPhotos,
					topPhotos: cacheGatheringTopPhotos,
					hiddenPhotos: cacheGatheringHiddenPhotos,
					users: cacheMergeUsers,
					palette: {
						merge: true,
					},
				},
			},
			Community: {
				keyFields: ["communityId"],
				merge: true,
				fields: {
					gatherings: cacheCommunityGatherings,
					users: cacheMergeUsers,
				},
			},
			Thumbnail: {
				merge: (existing, incoming, { readField, mergeObjects }) => {
					if (!existing) {
						return incoming;
					}
					const incomingReadUrl = readField<SignedUrl>("readUrl", incoming);
					if (!incomingReadUrl) {
						return mergeObjects(existing, incoming);
					}

					const incomingUrl =
						readField<string>("v0TemplateUrl", incomingReadUrl) ??
						readField<string>("stableUrl", incomingReadUrl) ??
						readField<string>("url", incomingReadUrl);
					const existingReadUrl = readField<SignedUrl>("readUrl", existing);
					const existingUrl = existingReadUrl
						? (readField<string>("v0TemplateUrl", existingReadUrl) ??
							readField<string>("stableUrl", existingReadUrl) ??
							readField<string>("url", existingReadUrl))
						: null;
					if (incomingUrl && existingUrl) {
						if (incomingUrl.includes("Placeholder") && existingUrl) {
							return existing;
						}
					}
					return mergeObjects(existing, incoming);
				},
			},
			StickerPack: {
				keyFields: ["stickerPackId"],
				merge: true,
			},
			Photo: {
				keyFields: ["photoId"],
				merge: true,
				fields: {
					readUrl: {
						merge: true,
					},
					writeUrl: {
						merge: true,
					},
					reactionUsers: cacheMergeReactionUsers,
					tags: {
						merge: false,
					},
				},
			},
			ReactionGroup: {
				merge: true,
			},
			PublicAsset: {
				merge: true,
				keyFields: ["assetId"],
			},
			Recap: {
				merge: true,
				keyFields: ["recapId"],
			},
			Query: {
				fields: {
					getClientProfile: {
						merge: (
							existing: GetClientProfileMinimalQuery["getClientProfile"],
							incoming: GetClientProfileMinimalQuery["getClientProfile"],
							{ cache, readField },
						) => {
							if (incoming) {
								const userId = readField<string>("userId", incoming);
								if (userId) {
									cache.writeQuery({
										query: GetUserMinimalDocument,
										data: {
											getUser: {
												__typename: "User",
												userId,
											},
										},
									});
								}
							}
							return incoming ?? existing;
						},
					},
					searchUsers: {
						keyArgs: false,
						merge: (
							existing: {
								cursor: Record<string, string>;
								users: Record<string, User>;
							} = { cursor: {}, users: {} },
							incoming: CursorUsers,
							{
								args,
								readField,
							}: FieldFunctionOptions<Record<string, any>, Record<string, any>>,
						) => {
							const merged: {
								cursor: Record<string, string>;
								users: Record<string, User>;
							} = {
								cursor: {
									...existing.cursor,
								},
								users: {
									...existing.users,
								},
							};
							merged.cursor[`Cursor:${args?.search.toLowerCase() ?? ""}`] =
								incoming.cursor ?? "{}";
							(incoming.users ?? []).forEach((user) => {
								merged.users[readField("userId", user) + ""] = user;
							});
							return merged;
						},
						read: (
							existing: {
								cursor: Record<string, string>;
								users: Record<string, User>;
							} = { cursor: {}, users: {} },
							{ args, readField },
						) => {
							var retUsers: User[] = [];
							Object.values(existing.users).forEach((userRef) => {
								if (
									args?.search &&
									!`${readField(
										"firstName",
										userRef,
									)} ${readField("lastName", userRef)}
                                `
										.toLowerCase()
										.includes(args.search.toLowerCase())
								) {
									return;
								}
								retUsers.push(userRef);
							});
							retUsers = retUsers.sort((aRef, bRef) => {
								let aRelevance: number = readField("relevance", aRef) ?? -1;
								let bRelevance: number = readField("relevance", bRef) ?? -1;

								return (bRelevance as number) - (aRelevance as number);
							});

							return {
								cursor:
									existing.cursor[`Cursor:${args?.search.toLowerCase() ?? ""}`] ??
									undefined,
								users: retUsers,
							};
						},
					},
					getContactsOnApp: {
						keyArgs: false,
					},
					getUser: {
						keyArgs: ["userId"],
						read: (_, { args, toReference }) => {
							if (!args?.userId) {
								log.warn("No userId provided");
								return null;
							}
							return toReference({
								__typename: "User",
								userId: args.userId ?? "",
							});
						},
					},
					getUsers: {
						keyArgs: false,
						merge: (
							existing: Record<string, User> = {},
							incoming: User[],
							{ readField }: FieldFunctionOptions,
						) => {
							const merged: Record<string, User> = {
								...existing,
							};
							incoming.forEach((user) => {
								const userId = readField<string>("userId", user);
								if (userId) {
									merged[userId] = user;
								}
							});
							return merged;
						},
						read: (
							existing: Record<string, User> = {},
							{ args, cache }: FieldFunctionOptions,
						) => {
							const retUsers: User[] = [];
							for (const userId of args?.userIds ?? []) {
								const userRef = cache.readFragment({
									id: cache.identify({
										__typename: "User",
										userId: userId,
									}),
									fragment: UserFragmentFragmentDoc,
								});
								if (userRef) {
									retUsers.push(userRef);
									continue;
								}
								const user = existing[userId];
								if (user) {
									retUsers.push(user);
								}
							}

							return retUsers;
						},
					},
					getGatherings: cacheQueryGetGatherings,
					getGathering: {
						keyArgs: ["input", ["gatheringId", "refCode"]],
					},
					getCommunities: cacheQueryGetCommunities,
				},
			},
		},
	});

	return cache;
};
