import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import { FocusWrapperProps } from "./types";

const FocusWrapper = ({ children, style, testID }: FocusWrapperProps) => {
	const { style: webStyle } = useParseViewPropsToWeb({
		style,
		pointerEvents: "auto",
	});
	delete webStyle?.border;

	return (
		<div className="focusParent overrideFocus" style={webStyle} data-testid={testID}>
			{children}
		</div>
	);
};
export default FocusWrapper;
