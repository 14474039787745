import config from "src/config";

const sanitizeCallback = (callback: string | null | undefined): string | null => {
	if (!callback) return null;
	if (callback.startsWith("/")) return callback;
	if (
		callback.startsWith(`${config.share.base}/`) ||
		callback.startsWith("https://www.joinswsh.com/")
	) {
		return callback;
	}
	if (callback.startsWith(`${window.location.origin}/`)) {
		return callback;
	}
	return null;
};

export default sanitizeCallback;
