import { ForwardedRef, useImperativeHandle } from "react";
import { View } from "react-native";
import { LabeledTextInputHandler, LabeledTextInputProps } from "../types";
import LabelHeaderRow from "./LabelHeaderRow";
import ValidCheck from "./ValidCheck";

const LabeledTextInputCustomChildren = ({
	label,
	children,
	showValidator,
	value,
	validator,
	showCharacterLimit,
	characterLimit,
	parentRef,
}: Omit<LabeledTextInputProps, "style"> & {
	parentRef: ForwardedRef<LabeledTextInputHandler>;
}) => {
	useImperativeHandle(parentRef, () => ({
		clear: () => {},
		focus: () => {},
		setValue: () => {},
		blur: () => {},
	}));

	return (
		<>
			<LabelHeaderRow
				label={label}
				characterLimit={characterLimit}
				showCharacterLimit={showCharacterLimit}
				value={value}
			/>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				{children}
				{showValidator && <ValidCheck value={value} validator={validator} />}
			</View>
		</>
	);
};
export default LabeledTextInputCustomChildren;
