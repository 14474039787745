import { ApolloClient, ApolloQueryResult } from "@apollo/client";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import { MIN_TO_S } from "src/helpers/constants";
import log from "src/helpers/log";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import { getGlobalStore, setGlobalStore } from "src/stores/useGlobalStore";
import { GetClientProfileQuery } from "./__generated__/graphql";

/**
 * Extension of `onValidAuthorization` to query any data that should be loaded every once in a while.
 *
 * You can expect this to be called whenever a user is first authorized, when they finish onboarding, when they open the app after a long period of time, etc.
 *
 * await anything that's important to wait for as a blocker (for example for authorization). Anything awaited will block authorization
 */
const onValidOpen = async (apolloClient: ApolloClient<object>) => {
	const prevOpen = getGlobalStore().prevOnValidOpen;
	let getClientProfilePromise:
		| Promise<Pick<ApolloQueryResult<GetClientProfileQuery>, "data">>
		| undefined;
	try {
		const promises: Promise<any>[] = [];
		const cur_S = getCurTime_S();
		if (prevOpen == null || cur_S - prevOpen > 2 * MIN_TO_S) {
			getClientProfilePromise = apolloClient.query({
				query: GetClientProfileDocument,
				fetchPolicy: "network-only",
			});

			// Only want to prefetch if we don't already have cached data
			const getClientProfileData = apolloClient.readQuery({
				query: GetClientProfileDocument,
			});
			if (!getClientProfileData?.getClientProfile) {
				log.info("Fetching missing client profile data");
				promises.push(getClientProfilePromise);
			}
			setGlobalStore({ prevOnValidOpen: cur_S });
		} else {
			const profile = apolloClient.readQuery({
				query: GetClientProfileDocument,
			});
			if (
				!profile?.getClientProfile ||
				// Don't want to refetch if the profile picture is missing since that's a valid state
				// Only if it exists and is a local image
				(profile.getClientProfile.profilePicture?.url &&
					profile.getClientProfile.profilePicture.url.startsWith("blob"))
			) {
				log.info("Fetching missing client profile data");
				getClientProfilePromise = apolloClient.query({
					query: GetClientProfileDocument,
					fetchPolicy: "network-only",
				});
				promises.push(getClientProfilePromise);
			} else {
				getClientProfilePromise = new Promise((resolve) => resolve({ data: profile }));
			}
		}
		await Promise.all(promises);
		return { getClientProfilePromise };
	} catch (err) {
		log.error({ err }, "Error prefetching data");
		return { getClientProfilePromise };
	}
};

export default onValidOpen;
