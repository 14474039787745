import { ApolloLink } from "@apollo/client";
import log from "src/helpers/log";
import useGlobalStore from "src/stores/useGlobalStore";
import onValidAuthorization from "../onValidAuthorization";

const checkHeaderAuthorizationMiddleware = new ApolloLink((operation, forward) => {
	const { headers } = operation.getContext();
	const hasAuthorization = !!headers?.authorization;
	const hasOperationHash = !!headers?.["x-operation-hash"];
	const hasOperationVersion = !!headers?.["x-operation-version"];

	if (hasAuthorization && hasOperationHash && hasOperationVersion) {
		return forward(operation).map((response) => {
			const context = operation.getContext();
			const fetchRes = context.response;

			const xUserId = fetchRes?.headers?.get("x-user-id");
			// loggedOut: false handled by errorMiddlware
			if (fetchRes.status === 200 && xUserId) {
				const { loggedIn, clientId, userType, apolloClient } = useGlobalStore.getState();
				if (!loggedIn || clientId !== xUserId) {
					useGlobalStore.setState({
						loggedIn: true,
						clientId: xUserId,
					});
					// This generally happens if local store lost data but we still have the cookie (usually different subdomain or browser clear)
					if (userType == null && apolloClient) {
						onValidAuthorization(apolloClient, {
							userId: xUserId,
						});
					}
				}
			}

			return response;
		});
	} else {
		log.warn(
			{
				hasAuthorization,
				hasOperationHash,
				hasOperationVersion,
			},
			"Missing required authorization headers",
		);
		return null;
	}
});

export default checkHeaderAuthorizationMiddleware;
