import { ApolloLink, Operation } from "@apollo/client";
import cookieStore from "src/api/auth/helpers/cookieStore";

export const processCookieInOperation = (operation: Operation) => {
	const context = operation.getContext();
	const response = context?.response as Response | undefined;
	const headers = response?.headers;

	const setCookieHeaders: string[] = [];
	headers?.forEach((value: string, name: string) => {
		if (name.toLowerCase() === "set-cookie") {
			setCookieHeaders.push(value);
		}
	});

	if (setCookieHeaders.length > 0) {
		cookieStore.setCookies(setCookieHeaders.join(", "));
	}
};

/**
 * No need to run this on web -- it won't do anything
 */
const cookieMiddleware = new ApolloLink((operation, forward) => {
	return forward(operation).map((response) => {
		processCookieInOperation(operation);

		return response;
	});
});

export default cookieMiddleware;
