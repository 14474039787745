import { track } from "@amplitude/analytics-react-native";
import type { ApolloClient } from "@apollo/client";
import { Platform } from "react-native";
import { amp } from "src/api/amplitude";
import LogoutDocument from "src/api/graphql/__generated__/documents/LogoutDocument";
import log from "src/helpers/log";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import useSplashScreenStore from "src/stores/useSplashScreenStore";
import clearState from "./clearState";
import forceReload from "./forceReload";

const handleLogout = async (apolloClient: ApolloClient<object> | undefined) => {
	if (!apolloClient) return;
	const res = await Promise.race([
		apolloClient
			.mutate({
				mutation: LogoutDocument,
				fetchPolicy: "no-cache",
			})
			.catch((err) => {
				log.error({ err }, "Error logging out");
			}),
		new Promise<null>((resolve) => setTimeout(() => resolve(null), 1000)),
	]);
	if (res === null) {
		log.error("Logout timed out");
	}
};

const isWeb = Platform.OS === "web";

/**
 * INTERNAL
 *
 * Do not use this function unless you know what you're doing with it. You almost definitely want `forceLogout` instead.
 *
 * This exists to clear up a nasty circular dependency between `forceLogout` and `AuthClient`.
 */
export const _forceLogout = async ({
	apolloClient,
	promises = [],
}: {
	apolloClient?: ApolloClient<object>;
	promises?: Promise<any>[];
} = {}) => {
	log.info("Forcing logout");

	// Resetting visuals
	useSplashScreenStore.setState({
		forceSplashScreen: true,
		logoutInitializedAt: getCurTime_MS(),
	});

	if (!isWeb) {
		// Do not run this on web immediately
		// It clears cookies and can force a redirect
		await handleLogout(apolloClient);
	}
	track(amp.auth.logout);

	await Promise.all([...promises, clearState()]).catch((err) => {
		log.error({ err }, "Error clearing state");
	});
	useSplashScreenStore.setState({
		rootDidRender: false,
		navigationReady: false,
	});
	setTimeout(() => {
		useSplashScreenStore.setState({
			logoutInitializedAt: null,
		});
	}, 3000);

	if (isWeb) {
		// No guarantees for web past this point since can be redirected
		await handleLogout(apolloClient);
		await forceReload();
	} else {
		await forceReload();
	}
};
