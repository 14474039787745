import { ApolloClient } from "@apollo/client";
import log from "src/helpers/log";
import { UploadAssetInfo } from "../../types";
import processCleanupUploadedPhotosBatch from "./helpers/processCleanupUploadedPhotosBatch";

const BatchSize = 100;
const handleConfirmAssets = async (
	apolloClient: ApolloClient<object>,
	images: UploadAssetInfo[],
): Promise<{
	success: boolean;
}> => {
	const imageLookup: Record<string, UploadAssetInfo> = {};
	const confirmImages: { photoId: string; localPhotoId: string }[] = [];
	for (const image of images) {
		imageLookup[image.localPhotoId] = image;
		if (image.uploadedPhotoInfo?.photoId) {
			confirmImages.push({
				photoId: image.uploadedPhotoInfo.photoId,
				localPhotoId: image.localPhotoId,
			});
		} else {
			log.error({ localPhotoId: image.localPhotoId }, "Image does not have a photoId");
		}
	}

	const confirmImagesBatches: { photoId: string; localPhotoId: string }[][] = [];
	for (let i = 0; i < confirmImages.length; i += BatchSize) {
		confirmImagesBatches.push(confirmImages.slice(i, i + BatchSize));
	}

	for (const batch of confirmImagesBatches) {
		await processCleanupUploadedPhotosBatch(batch, {
			apolloClient,
			imageLookup,
		});
	}
	return {
		success: true,
	};
};
export default handleConfirmAssets;
