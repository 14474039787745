import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAssetPersist from "src/api/asset-persist/stores/useAssetPersist";
import { DownloadAssetInfo, UploadAssetInfo } from "src/api/asset-persist/types";
import promptReview from "src/helpers/promptReview";
import IconDownload from "src/icons/IconDownload";
import IconUpload from "src/icons/IconUpload";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import { Timestamp_MS } from "src/shared/types/general";
import { UploadDownloadInfo } from "../components/UploadDownloadToastView/types";

interface AssetInfo {
	id: string;
	previewUrl: string | null;
	didFail: boolean;
}

const parseUpload = (uploadingAssets: UploadAssetInfo[]): AssetInfo[] => {
	return uploadingAssets
		.filter((image) => image.uploadState === "Pending" || image.uploadState === "Failed")
		.map((image) => ({
			id: image.localPhotoId,
			previewUrl: image.uriInfo.image?.uri ?? null,
			didFail: image.uploadState === "Failed",
		}));
};
const parseDownload = (downloadingAssets: DownloadAssetInfo[]): AssetInfo[] => {
	return downloadingAssets.map((image) => ({
		id: image.assetId,
		previewUrl: image.image?.previewUrl ?? null,
		didFail: image.didFail ?? false,
	}));
};
type Variant = "upload" | "download";
const parseValues = (
	variant: Variant,
	values: DownloadAssetInfo[] | UploadAssetInfo[],
): AssetInfo[] => {
	switch (variant) {
		case "upload":
			return parseUpload(values as UploadAssetInfo[]);
		case "download":
			return parseDownload(values as DownloadAssetInfo[]);
		default:
			return enumNever(variant);
	}
};
export const parseAssetCountCopy = (
	variant: Variant,
): Pick<UploadDownloadInfo, "completionCopy" | "progressCopy" | "Icon"> => {
	switch (variant) {
		case "upload":
			return {
				progressCopy: "Love it!!",
				completionCopy: "Uploaded!",
				Icon: IconUpload,
			};
		case "download":
			return {
				progressCopy: "Way to go!!",
				completionCopy: "Downloaded!",
				Icon: IconDownload,
			};
		default:
			return enumNever(variant);
	}
};

const parseStoreKey = (variant: Variant) => {
	switch (variant) {
		case "upload":
			return "uploadAssetsStore";
		case "download":
			return "downloadAssetsStore";
		default:
			return enumNever(variant);
	}
};

export const createUseAssetCountInfo = (variant: Variant) => {
	const useNumExtra =
		variant === "upload"
			? () => {
					const numExtra = useAssetPersist((state) => state.numPrequeuedUploadAssets);
					return numExtra;
				}
			: () => 0;
	const storeKey = parseStoreKey(variant);
	const copy = parseAssetCountCopy(variant);

	const useAssetCountInfo = () => {
		const seenIds = useRef(new Set<string>());
		const numExtra = useNumExtra();
		const [numActive, setNumActive] = useState(0);
		const [numComplete, setNumComplete] = useState(0);
		const numTotal = useMemo(
			() => Math.max(numActive, numExtra, numComplete),
			[numActive, numComplete, numExtra],
		);
		const [curNumTotal, setCurNumTotal] = useState(0);
		const isComplete = useMemo(() => {
			return curNumTotal + numExtra === 0;
		}, [curNumTotal, numExtra]);
		const [previewImage, setPreviewImage] =
			useState<UploadDownloadInfo["previewImage"]>(undefined);
		const prevPreviewImageUpdate = useRef<Timestamp_MS | null>(null);

		useEffect(() => {
			const unsubscribe = useAssetPersist.subscribe(
				(state) => state[storeKey],
				(store) => {
					const assets = parseValues(variant, Object.values(store));

					let newNumComplete = 0;

					const curLoading = new Set(assets.map((v) => v.id));
					for (const id of Array.from(seenIds.current)) {
						if (!curLoading.has(id)) {
							newNumComplete++;
						}
					}

					// Handling calculating the new total
					for (const asset of assets) {
						if (!seenIds.current.has(asset.id)) {
							seenIds.current.add(asset.id);
						}
						if (asset.didFail) {
							seenIds.current.delete(asset.id);
						}
					}

					// Complete
					if (seenIds.current.size === newNumComplete && newNumComplete > 0) {
						promptReview();
					}

					setNumActive(seenIds.current.size);
					setNumComplete(newNumComplete);
					setCurNumTotal(assets.length);

					const cur = getCurTime_MS();
					if (
						!prevPreviewImageUpdate.current ||
						cur - prevPreviewImageUpdate.current > 2000
					) {
						prevPreviewImageUpdate.current = cur;
						for (const asset of assets) {
							if (asset.previewUrl) {
								setPreviewImage({
									id: asset.id,
									url: asset.previewUrl,
								});
								break;
							}
						}
					}
				},
			);
			return unsubscribe;
		}, [variant]);
		const handleClear = useCallback(() => {
			seenIds.current.clear();
			setPreviewImage(undefined);
		}, []);

		return {
			numTotal,
			numComplete,
			isComplete,
			variant,
			onClear: handleClear,
			previewImage,
			...copy,
		} satisfies UploadDownloadInfo;
	};

	return useAssetCountInfo;
};
