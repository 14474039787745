import { ApolloLink, makeVar } from "@apollo/client";
import { curEnv } from "src/config";
import clientVersion from "src/constants/clientVersion";
import { alert } from "src/swsh-native";

const didPrompt = makeVar(false);
const versionMiddleware = new ApolloLink((operation, forward) => {
	operation.setContext((context: any) => ({
		...context,
		headers: {
			...context.headers,
			"x-client-version": clientVersion,
			"x-branch": curEnv,
		},
	}));

	return forward(operation).map((response) => {
		const context = operation.getContext();

		const xForceUpdateVersion = context.response?.headers?.get("x-force-update-version");
		if (xForceUpdateVersion && !didPrompt()) {
			didPrompt(true);
			alert({
				title: "Reload Required",
				message:
					"We've made some changes that require you to reload the page. If you run into any issues, try clearing your browser cache or emailing contact@joinswsh.com. Sorry for the inconvenience!",
				buttons: [
					{
						text: "Reload",
						onPress: () => {
							window.location.reload();
						},
					},
				],
			});
		}

		return response;
	});
});

export default versionMiddleware;
