import featureFactory from "./helpers/featureFactory";

export const initFeatureControls = (userId: string) =>
	featureFactory(userId)
		.feature("noop", ({ random }) => {
			return random() < 0.5 ? "A" : "B";
		})
		.feature("noop30", ({ random }) => {
			return random() < 0.3 ? "A" : "B";
		})
		.summarize();
