"use client";
import { ReactElement, useEffect } from "react";
import { usePathname } from "solito/navigation";
import { amplitudeAddData } from "src/api/amplitude/AmplitudeStore";

const NavigationProvider = ({ children }: { children: ReactElement }) => {
	const pathName = usePathname();

	useEffect(() => {
		amplitudeAddData({ path: pathName });
	}, [pathName]);

	return children;
};

export default NavigationProvider;
