import { identify, Identify } from "@amplitude/analytics-react-native";
import { useEffect } from "react";
import isClip from "src/constants/isClip";

const useUserProperties = () => {
	useEffect(() => {
		const { timeZone: timezone, locale } = Intl.DateTimeFormat().resolvedOptions();

		const identifyObj = new Identify();

		identifyObj.set("timezone", timezone);
		identifyObj.set("locale", locale);
		identifyObj.set("isClip", isClip);
		identify(identifyObj);
	}, []);
};
export default useUserProperties;
