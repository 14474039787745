import useUserProperties from "src/api/amplitude/useUserProperties";
import revalidateQueryCache from "src/api/graphql/variables/revalidateQueryCache";
import usePersistVariable from "src/api/mmkv/hooks/usePersistVariable";
import useClientOnlineInterval from "./hooks/useClientOnlineInterval";

const useInternalHooks = () => {
	useUserProperties();
	useClientOnlineInterval();
	usePersistVariable({
		revalidateQueryCache: revalidateQueryCache,
	});
};
export default useInternalHooks;
