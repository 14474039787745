import { useCallback, useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Touchable from "src/components/Touchable";
import useKeyboardListener from "src/hooks/useKeyboardListener";
import useStateRef from "src/hooks/useStateRef";
import useStyle from "src/hooks/useStyle";
import IconClose from "src/icons/IconClose";
import IconLeft from "src/icons/IconLeft";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { Text, useSafeArea } from "src/swsh-native";
import Loading from "../Loading";
import { HEADER_SPACING } from "./constants";

export interface HeaderProps {
	exitVariant?: "Cancel" | "Arrow" | "Close" | "None" | "Replace";
	completeVariant?: "Skip" | "Done" | "None" | "Save" | "Choose";
	onExit?: () => void;
	onComplete?: () => void | Promise<void>;
	children?: string;
	addSafeArea?: boolean;
	/**
	 * Automatically sets a loading prop based on `onComplete`
	 *
	 * @default true for `completeVariant` === "Done" and `completeVariant` === "Save"
	 */
	autoLoadingForOnComplete?: boolean;

	keyboardControls?: boolean;

	variant?: "Modal" | "Screen" | "Transparent";
	style?: StyleProp<ViewStyle>;
}

const HIT_SLOP = 15;
const Header = ({
	addSafeArea = false,
	exitVariant = "None",
	completeVariant = "None",
	onExit,
	onComplete,
	children,
	autoLoadingForOnComplete: autoLoadingForOnCompleteProp,
	variant = "Screen",
	keyboardControls,
	style,
}: HeaderProps) => {
	const { theme } = useStyle();
	const safeArea = useSafeArea();
	const [completeLoading, setCompleteLoading, completeLoadingRef] = useStateRef(false);
	const autoLoadingForOnComplete = useMemo(() => {
		if (autoLoadingForOnCompleteProp !== undefined) {
			return autoLoadingForOnCompleteProp;
		}
		if (completeVariant === "Done" || completeVariant === "Save") {
			return true;
		}
		return false;
	}, [autoLoadingForOnCompleteProp, completeVariant]);

	const TopLeftComponent = useMemo(() => {
		switch (exitVariant) {
			case "Cancel":
				return (
					<Touchable onPress={onExit} testID="cancel-button">
						<Text>Cancel</Text>
					</Touchable>
				);
			case "Arrow":
				return (
					<Touchable onPress={onExit} testID="back-button" hitSlop={HIT_SLOP}>
						<IconLeft size={18} />
					</Touchable>
				);
			case "Close":
				return (
					<Touchable onPress={onExit} testID="close-button" hitSlop={HIT_SLOP}>
						<IconClose size={18} />
					</Touchable>
				);
			case "Replace":
				return (
					<Touchable onPress={onExit} testID="replace-button">
						<Text semibold>Replace</Text>
					</Touchable>
				);
			case "None":
				return null;
			default:
				return enumNever(exitVariant);
		}
	}, [exitVariant, onExit]);

	const handleComplete = useCallback(async () => {
		try {
			if (!onComplete) return;
			if (autoLoadingForOnComplete && completeLoadingRef.current) return;
			setCompleteLoading(true);
			await onComplete();
		} finally {
			setCompleteLoading(false);
		}
	}, [autoLoadingForOnComplete, completeLoadingRef, onComplete, setCompleteLoading]);

	const TopRightComponent = useMemo(() => {
		if (completeLoading && autoLoadingForOnComplete) {
			return <Loading />;
		}
		switch (completeVariant) {
			case "Skip":
				return (
					<Touchable onPress={handleComplete}>
						<Text>Skip</Text>
					</Touchable>
				);
			case "Save":
				return (
					<Touchable onPress={handleComplete}>
						<Text semibold>Save</Text>
					</Touchable>
				);
			case "Done":
				return (
					<Touchable onPress={handleComplete}>
						<Text semibold>Done</Text>
					</Touchable>
				);
			case "Choose":
				return (
					<Touchable onPress={handleComplete}>
						<Text semibold>Choose</Text>
					</Touchable>
				);
			case "None":
				return null;
			default:
				return enumNever(completeVariant);
		}
	}, [completeVariant, handleComplete, completeLoading, autoLoadingForOnComplete]);
	const containerStyle = useMemo<ViewStyle>(() => {
		switch (variant) {
			case "Modal":
				return {
					backgroundColor: color.BackgroundModal[theme],
				};
			case "Screen":
				return {
					backgroundColor: color.Background[theme],
				};
			case "Transparent":
				return {
					backgroundColor: "transparent",
				};
			default:
				return enumNever(variant);
		}
	}, [variant, theme]);

	useKeyboardListener({
		Enter: keyboardControls ? handleComplete : undefined,
		Escape: keyboardControls ? onExit : undefined,
	});

	return (
		<View
			style={[
				HEADER_SPACING,
				{
					paddingTop: HEADER_SPACING.paddingTop + (addSafeArea ? safeArea.top : 0),
					width: "100%",
				},
				containerStyle,
				style,
			]}
		>
			<View
				style={{
					width: "100%",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{TopLeftComponent}
				{/*
                 Used for sizing
                */}
				<Text
					semibold
					style={{
						textAlign: "center",
						maxWidth: "60%",
						opacity: 0,
					}}
				>
					{children ?? " "}
				</Text>
				<View
					style={{
						position: "absolute",
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
					pointerEvents="none"
				>
					<Text
						semibold
						style={{
							textAlign: "center",
							maxWidth: "60%",
						}}
						testID={`Header-${children}`}
					>
						{children ?? " "}
					</Text>
				</View>
				{TopRightComponent}
			</View>
		</View>
	);
};
export default Header;
