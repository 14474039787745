import { BaseSyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import {
	DimensionValue,
	LayoutChangeEvent,
	TextInputChangeEventData,
	TextInputProps,
	View,
} from "react-native";
import useDim from "src/hooks/useDim";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { radiusMedium } from "src/styles/radius";
import { paddingLarge, paddingXL, paddingXXL, paddingXXXL } from "src/styles/spacing";
import popupCloseSelf from "src/swsh-native/Popups/helpers/popupCloseSelf";
import sortButtons from "src/swsh-native/Popups/helpers/sortButtons";
import { PopupButtonInfo, PopupInfo } from "src/swsh-native/Popups/stores/usePopupStore";
import Text from "src/swsh-native/Text";
import PromptTextInput from "../../PromptProvider/components/PromptTextInput";
import WebPopupBox from "./WebPopupBox.web";
import WebPopupButton from "./WebPopupButton.web";

const buttonPaddingHorizontal = paddingXL;

const WebPopupContent = ({ popup }: { popup: PopupInfo }) => {
	const { theme } = useStyle();
	const dim = useDim();
	const buttons = useMemo(() => {
		return sortButtons(popup.buttons);
	}, [popup.buttons]);
	const [buttonWidth, setButtonWidth] = useState<DimensionValue | null>(null);
	const largestButtonWidth = useRef<number>(0);
	const maxWidth = useMemo(() => Math.min(dim.width - 2 * paddingXXL, 400), [dim.width]);

	const handleButtonLayout = useCallback(
		(event: LayoutChangeEvent) => {
			if (event.nativeEvent.layout.width > largestButtonWidth.current) {
				largestButtonWidth.current = event.nativeEvent.layout.width;

				// Stretch buttons to fit width minus padding (if <2 buttons then stretch to half width)
				setButtonWidth(
					Math.max(
						largestButtonWidth.current,
						maxWidth / (buttons.length < 2 ? 2 : buttons.length) -
							buttonPaddingHorizontal * 2,
					),
				);
			}

			// Text got clipped so we wrap buttons
			if (event.nativeEvent.layout.height > 30) {
				setButtonWidth("100%");
			}
		},
		[buttons, maxWidth],
	);

	const promptText = useRef("");

	const formatButtonArgs = useCallback(
		(
			buttonInfo: PopupButtonInfo,
		): Omit<PopupButtonInfo, "onPress"> & {
			onPress: () => void;
		} => {
			const onPress = () => {
				popupCloseSelf(popup);
				if (popup.variant === "Prompt") {
					buttonInfo.onPress?.(promptText.current);
				} else {
					buttonInfo.onPress?.();
				}
			};
			return {
				...buttonInfo,
				onPress,
			};
		},
		[popup],
	);

	// This is a hacky solution because TextInput does not autogrow/shrink on web (credit @willstepp)
	// See: https://github.com/necolas/react-native-web/issues/795
	const adjustTextInputSize = useCallback(
		(el: BaseSyntheticEvent<TextInputProps, TextInputProps>["target"]) => {
			el.style.height = 0;
			const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
			el.style.height = `${newHeight}px`;
		},
		[],
	);

	const onTextInputLayout = useCallback(
		(event: LayoutChangeEvent) => {
			const element = event?.target;
			if (element) {
				adjustTextInputSize(element);
			}
		},
		[adjustTextInputSize],
	);

	const onTextInputChange = useCallback(
		(event: BaseSyntheticEvent<TextInputChangeEventData>) => {
			const element = event?.target || event?.nativeEvent?.target;
			if (element) {
				adjustTextInputSize(element);
			}
		},
		[adjustTextInputSize],
	);

	return (
		<WebPopupBox
			webTestID={popup.webTestID}
			style={{
				backgroundColor: color.BackgroundModal[theme],
				borderRadius: radiusMedium,
				minWidth: popup.variant === "Prompt" ? 356 : 200,
				minHeight: 100,
				paddingHorizontal: paddingXXXL,
				paddingVertical: paddingXXXL,
				flexDirection: "column",
				justifyContent: "center",
				opacity: buttonWidth === null ? 0 : 1,
				maxWidth,
				rowGap: paddingLarge,

				shadowColor: "#000000",
				shadowOffset: {
					width: 0,
					height: 2,
				},
				shadowOpacity: 0.3,
				shadowRadius: 2.62,
				elevation: 4,
			}}
		>
			<View
				style={{
					flex: 1,
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					rowGap: 6,
					marginBottom: popup.variant === "Alert" ? 12 : 0,
				}}
			>
				{popup.title && (
					<Text
						testID="PopupTitle"
						semibold
						style={{
							textAlign: "center",
						}}
					>
						{popup.title}
					</Text>
				)}
				{popup.message && (
					<Text
						testID="PopupMessage"
						xs
						style={{
							textAlign: "center",
						}}
					>
						{popup.message}
					</Text>
				)}
			</View>
			{popup.variant === "Prompt" && (
				<PromptTextInput
					promptInfo={popup}
					onChangeText={(text) => {
						promptText.current = text;
					}}
					onSubmitEditing={() => {
						for (const button of buttons) {
							if (button.style === "default") {
								button.onPress?.(promptText.current);
							}
						}
						popupCloseSelf(popup);
					}}
					onChange={onTextInputChange}
					onLayout={onTextInputLayout}
				/>
			)}
			<View
				style={{
					width: "100%",
					flexDirection: "row",
					justifyContent: buttons.length === 1 ? "center" : "space-between",
					columnGap: paddingLarge,
					flexWrap: "wrap-reverse",
					rowGap: 10,
				}}
			>
				{buttons.length > 0 ? (
					buttons.map((buttonInfo, index) => (
						<WebPopupButton
							width={buttonWidth}
							paddingHorizontal={buttonPaddingHorizontal}
							onLayout={handleButtonLayout}
							key={index}
							buttonInfo={formatButtonArgs(buttonInfo)}
						/>
					))
				) : popup.variant === "Alert" ? (
					<WebPopupButton
						width={buttonWidth}
						onLayout={handleButtonLayout}
						buttonInfo={formatButtonArgs({
							text: "OK",
							style: "default",
							isPreferred: true,
						})}
					/>
				) : (
					<>
						<WebPopupButton
							width={buttonWidth}
							onLayout={handleButtonLayout}
							buttonInfo={formatButtonArgs({
								text: "Cancel",
								style: "cancel",
								onPress: () => {
									popupCloseSelf(popup);
								},
							})}
						/>
						<WebPopupButton
							width={buttonWidth}
							onLayout={handleButtonLayout}
							buttonInfo={formatButtonArgs({
								text: "OK",
								style: "default",
								isPreferred: true,
							})}
						/>
					</>
				)}
			</View>
		</WebPopupBox>
	);
};
export default WebPopupContent;
