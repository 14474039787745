import { createHttpLink } from "@apollo/client";
import config, { isDev } from "src/config";
import createFetchWithRetry from "src/shared/helpers/createFetchWithRetry";

const getUrl = (isFallback?: boolean) => {
	if (isDev && process.env.GRAPHQL_API) {
		return process.env.GRAPHQL_API;
	}
	if (isFallback) {
		return config.graphqlConfig.fallback_endpoint;
	}
	return config.graphqlConfig.api_endpoint;
};

const httpLink = createHttpLink({
	uri: getUrl(),
	credentials: "include",
	fetch: createFetchWithRetry({
		rewriteRequest: (url, options, { numAttempts }) => {
			if (numAttempts > 0) {
				return { url: getUrl(true), options };
			}
			return { url, options };
		},
	}),
});
export default httpLink;
