"use client";
import { useEffect, useMemo, useRef, useState } from "react";
import log from "src/helpers/log";
import useSplashScreenStore from "src/stores/useSplashScreenStore";
import toast from "src/swsh-native/toast";
import FadeInLoading from "./components/FadeInLoading";
import splashScreenTasks from "./helpers/splashScreenTasks";

const SplashScreen = () => {
	const storeIsReady = useSplashScreenStore(
		(state) => state.navigationReady && state.rootDidRender && !state.forceSplashScreen,
	);
	const [isReady, setIsReady] = useState(splashScreenTasks.promises.length === 0);
	const interval = useRef<NodeJS.Timeout | null>(null);
	const start = useMemo(() => performance.now(), []);
	const splashScreenCompleted = isReady && storeIsReady;

	useEffect(() => {
		if (splashScreenTasks.promises.length > 0) {
			Promise.all(splashScreenTasks.promises)
				.then(async () => {
					setIsReady(true);
					splashScreenTasks.promises = [];
				})
				.catch((error) => {
					toast({
						title: "Error loading app",
						message: "Please try again later",
						preset: "error",
					});
					throw error;
				});
		}
	}, []);
	useEffect(() => {
		if (splashScreenCompleted) return;
		interval.current = setInterval(() => {
			const diff = performance.now() - start;
			if (diff > 2000) {
				log.warn(
					{
						splashScreenDurationMS: diff,
					},
					`Splash screen taking too long`,
				);
			}
		}, 2000);
		return () => {
			interval.current && clearInterval(interval.current);
		};
	}, [splashScreenCompleted, start]);

	return <>{!splashScreenCompleted && <FadeInLoading testID="SplashScreen" />}</>;
};
export default SplashScreen;
