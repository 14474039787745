import { CookieStore } from "./types";

const cookieStore: CookieStore = {
	getCookies: async () => document.cookie,
	setCookies: async () => {},
	clearCookies: async () => {},
	setCookiesRaw: async () => {},
	getCookiesRaw: async () => "{}",
};

export default cookieStore;
