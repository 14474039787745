import { useMemo } from "react";
import { DimensionValue } from "react-native";
import useDim from "src/hooks/useDim";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import fontFamilies from "src/styles/fontFamilies";
import { xsFont } from "src/styles/fonts";
import { paddingMedium } from "src/styles/spacing";
import { PromptInfo } from "src/swsh-native/Popups/stores/usePopupStore";
import { useSafeArea } from "src/swsh-native/SafeArea";
import TextInput, { TextInputProps } from "src/swsh-native/TextInput";

export interface PromptTextInputProps extends TextInputProps {
	promptInfo?: PromptInfo;
	minHeight?: DimensionValue;
	maxHeight?: DimensionValue;
}

const defaultMinHeight = 80;
const PromptTextInput = ({ minHeight, maxHeight, promptInfo, ...props }: PromptTextInputProps) => {
	const { theme } = useStyle();
	const safeArea = useSafeArea();
	const dim = useDim();
	const defaultMaxHeight = useMemo(
		() => Math.min(274, (dim.height - safeArea.top - safeArea.bottom) / 2),
		[dim.height, safeArea],
	);

	return (
		<TextInput
			testID="PromptTextInput"
			autoFocus
			multiline
			textAlignVertical="top"
			style={{
				minHeight: minHeight ?? defaultMinHeight,
				maxHeight: maxHeight ?? defaultMaxHeight,
				fontSize: xsFont.fontSize,
				fontFamily: fontFamilies.semibold,
				fontWeight: "600",
				paddingHorizontal: paddingMedium,
				paddingVertical: paddingMedium,
				borderColor: color.Divider[theme],
				borderWidth: 1,
				borderRadius: 12,
			}}
			keyboardType={promptInfo?.keyboardType}
			value={promptInfo?.defaultValue}
			{...props}
		/>
	);
};
export default PromptTextInput;
