import SeededRandomGenerator from "src/helpers/SeededRandomGenerator";

const featureFactory = <TFeatures extends Record<string, boolean | number | string>>(
	userId: string,
	// @ts-expect-error
	features: TFeatures = {},
) => {
	const feature = <TRet extends boolean | number | string, TName extends string>(
		name: TName,
		fn: (helpers: { random: () => number }) => TRet,
	) => {
		const seededGenerator = new SeededRandomGenerator(`${userId}#${name}`);
		// Convert userId + name into number
		return featureFactory<TFeatures & Record<TName, TRet>>(userId, {
			...features,
			[name]: fn({
				random: () => seededGenerator.random(),
			}),
		});
	};
	const summarize = () => {
		return features;
	};
	return {
		feature,
		summarize,
	};
};
export default featureFactory;
