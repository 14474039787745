import { makeVar } from "@apollo/client";
import * as Application from "expo-application";
import Constants from "expo-constants";
import { useEffect } from "react";
import { SEND_ONLINE_UPDATES } from "src/api/featureFlags";
import ClientUpdateDocument from "src/api/graphql/__generated__/documents/ClientUpdateDocument";
import { MutationClientUpdateInput } from "src/api/graphql/__generated__/graphql";
import { useAsyncMutation } from "src/api/graphql/helper";
import mmkv from "src/api/mmkv";
import clientVersion from "src/constants/clientVersion";
import { DAY_TO_S } from "src/helpers/constants";
import { consoleDev } from "src/helpers/devHelpers";
import { getDeviceType } from "src/helpers/helperFunctions";
import useGlobalStore from "src/stores/useGlobalStore";

/**
 * Intelligently stores the client's data in the database.
 * Manages data changes and updates the database accordingly.
 * Prevents repeatedly updating the database with the same data.
 */
type ClientUpdateEntry = {
	value: any;
	lastUpdated_S: number;
};
type ClientUpdate = {
	[key in keyof MutationClientUpdateInput]: ClientUpdateEntry;
};

/**
 * How often to update the database with the same data.
 */
const UPDATE_REFRESH_S = DAY_TO_S * 3;
/**
 * Overrides for specific keys
 */
const UPDATE_REFRESH_OVERRIDE = {
	clientOnline: 60, // There's issues with off cycle interval timing for online pings, so reducing this to 1 minute so it's almost always counted
};

/**
 * Aggregates updates to the database to prevent spamming the database.
 * This window is the maximum amount of time to wait before sending an update.
 */
const SEND_AGGREGATION_WINDOW_S = 1000;
const CLIENT_UPDATE_KEY = "clientUpdate";
export const storedUpdateVar = makeVar<ClientUpdate | undefined>(undefined);
export const toSendVar = makeVar<MutationClientUpdateInput>({});
const getExisting = async () => {
	const existing = JSON.parse(mmkv.getString(CLIENT_UPDATE_KEY) ?? "{}") as ClientUpdate;
	return existing;
};
const useClientUpdate = () => {
	const loggedIn = useGlobalStore((state) => state.loggedIn);
	const clientUpdateMutation = useAsyncMutation(ClientUpdateDocument);
	/**
	 * Retrieves all the update items that are easy to retrieve
	 */
	const getBaselineParams = () => {
		const retValues: Partial<MutationClientUpdateInput> = {
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			deviceName: Constants.deviceName,
			deviceType: getDeviceType(),
			version: clientVersion,
		};
		if (Application.nativeApplicationVersion) {
			retValues.appVersion = `${Application.nativeApplicationVersion} (${Application.nativeBuildVersion})`;
		}
		return retValues;
	};
	const clientUpdate = async (params: Partial<MutationClientUpdateInput>) => {
		// Being mindful of asynchronous overwriting
		if (!storedUpdateVar()) {
			const existing = await getExisting();
			storedUpdateVar({ ...existing, ...(storedUpdateVar() ?? {}) });
		}
		let toUpdate: MutationClientUpdateInput = {};
		const newEntry: Partial<ClientUpdate> = {};
		const combinedParams: MutationClientUpdateInput = {
			...getBaselineParams(),
			...params,
		};
		if (!SEND_ONLINE_UPDATES) {
			delete combinedParams.clientOnline;
		}
		const existing = storedUpdateVar();
		if (!existing) throw new Error("existing is undefined");

		// Checks to see if values are the same, and handles refresh logic
		Object.entries(combinedParams).forEach(([key, value]: any) => {
			if (value === undefined) return;

			const t = Date.now() / 1000;
			const diffT = t - ((existing as any)[key]?.lastUpdated_S ?? 0);
			if (
				value === (existing as any)[key]?.value &&
				diffT <
					(key in UPDATE_REFRESH_OVERRIDE
						? (UPDATE_REFRESH_OVERRIDE as any)[key]
						: UPDATE_REFRESH_S)
			) {
				return;
			}

			(toUpdate as any)[key] = value;
			(newEntry as any)[key] = {
				value,
				lastUpdated_S: t,
			};
		});
		if (Object.keys(toUpdate).length === 0) return;

		storedUpdateVar({ ...existing, ...newEntry });
		if (toUpdate.pushToken) {
			// If pushToken is updated, we need to update the device type as well to override potential web
			toUpdate = {
				...getBaselineParams(),
				...toUpdate,
			};
		}

		// Handling actual update
		toSendVar({ ...toSendVar(), ...toUpdate });

		// Random delay to aggregate updates
		await new Promise((r) => setTimeout(r, Math.random() * SEND_AGGREGATION_WINDOW_S));

		mmkv.set(CLIENT_UPDATE_KEY, JSON.stringify(storedUpdateVar() ?? {}));
		// Handles sending
		const toSend = toSendVar();
		if (Object.keys(toSend).length === 0) return;
		toSendVar({});
		try {
			consoleDev(toSend, "Sending client update");
			await clientUpdateMutation({
				input: toSend,
			});
		} catch (error) {
			mmkv.delete(CLIENT_UPDATE_KEY);
			throw error;
		}
	};
	// biome-ignore lint/correctness/useExhaustiveDependencies: Should only run on login change
	useEffect(() => {
		if (loggedIn) {
			clientUpdate({});
		}
	}, [loggedIn]);
	return clientUpdate;
};
export default useClientUpdate;
