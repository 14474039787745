import { ApolloClient, ApolloLink } from "@apollo/client";
import { MMKVWrapper, persistCache } from "apollo3-cache-persist";

import { Platform } from "react-native";
import { API_RANDOM_BREAKS, DISABLE_PERSIST_APOLLO_CACHE } from "src/api/featureFlags";
import { getClientCache } from "src/api/graphql/cache";
import mmkv from "src/api/mmkv";
import { isDev } from "src/config";
import { consoleDevPerformanceDiff } from "src/helpers/devHelpers";
import { clientSchema } from "./clientSchema";
import authMiddleware from "./middleware/authMiddleware";
import checkHeaderAuthorizationMiddleware from "./middleware/checkHeaderAuthorizationMiddleware";
import cookieMiddleware from "./middleware/cookieMiddleware";
import errorMiddleware from "./middleware/errorMiddleware";
import httpLink from "./middleware/httpLink";
import loggingMiddleware from "./middleware/loggingMiddleware";
import randomFailureMiddleware from "./middleware/randomFailureMiddleware";
import signatureMiddleware from "./middleware/signatureMiddleware";
import versionMiddleware from "./middleware/versionMiddleware";

export const getApolloClient = async () => {
	const link = ApolloLink.from([
		errorMiddleware,
		...(isDev && API_RANDOM_BREAKS ? [randomFailureMiddleware] : []),
		signatureMiddleware,
		authMiddleware,
		checkHeaderAuthorizationMiddleware,
		versionMiddleware,
		...(Platform.OS !== "web" ? [cookieMiddleware] : []),
		...(isDev ? [loggingMiddleware] : []),
		// Retries are handled by httpLink based on fetch errors or bad status codes
		httpLink,
	]);

	const t = performance.now();
	const cache = getClientCache();
	if (!DISABLE_PERSIST_APOLLO_CACHE) {
		await persistCache({
			cache,
			storage: new MMKVWrapper(mmkv),
			debounce: Platform.OS === "web" ? 0 : 1000,
		});
	}
	consoleDevPerformanceDiff("GraphQL Cache Retrieval", t);

	return new ApolloClient({
		link,
		cache,
		typeDefs: clientSchema,
		assumeImmutableResults: true,
	});
};
