import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";

const ERROR_MARGIN = 0.1;
const LoadingBar = ({
	numTotal,
	numComplete,
	style,
}: {
	numTotal: number;
	numComplete: number;
	style?: StyleProp<ViewStyle>;
}) => {
	const numTotalRef = useRef(numTotal);
	const numCompleteRef = useRef(numComplete);
	useEffect(() => {
		numTotalRef.current = numTotal;
		numCompleteRef.current = numComplete;
	}, [numTotal, numComplete]);

	const { theme } = useStyle();
	/**
	 * Between 0 and 1. Loading bar progress.
	 */
	const progress = useSharedValue(0);
	useEffect(() => {
		const newProgress = numTotal === 0 ? 0 : numComplete / numTotal;
		if (newProgress === 0) {
			progress.value = 0;
		} else if (newProgress > progress.value || newProgress - ERROR_MARGIN < progress.value) {
			progress.value = withTiming(newProgress);
		}
	}, [numComplete, numTotal, progress]);

	const setArbitraryProgress = useCallback(() => {
		const correctNewProgress =
			numTotalRef.current === 0 ? 0 : numCompleteRef.current / numTotalRef.current;
		if (Math.random() < 0.5) {
			const newProgress = correctNewProgress + progress.value + Math.random() * 0.1;
			if (newProgress > 1 || newProgress > correctNewProgress + ERROR_MARGIN) {
				return;
			} else {
				progress.value = withTiming(newProgress, {
					duration: 800,
				});
			}
		}
	}, [progress]);
	useEffect(() => {
		if (numTotal > 0) {
			setArbitraryProgress();
			var interval = setInterval(() => {
				setArbitraryProgress();
			}, 400);
		}
		return () => {
			clearInterval(interval);
		};
	}, [numTotal, setArbitraryProgress]);

	const [width, setWidth] = useState(0);
	const animatedStyle = useAnimatedStyle(
		() => ({
			transform: [{ translateX: -((1 - progress.value) * width) }],
		}),
		[progress, width],
	);
	const handleLayout = useCallback((e: LayoutChangeEvent) => {
		setWidth(e.nativeEvent.layout.width);
	}, []);

	return (
		<View
			onLayout={handleLayout}
			style={[
				{
					backgroundColor: "#00000040",
					flex: 1,
					height: 8,
					borderRadius: 4,
					overflow: "hidden",
				},
				style,
			]}
		>
			<Animated.View
				style={[
					{
						backgroundColor: color.Text[theme],
						width: "100%",
						height: 8,
						borderRadius: 4,
					},
					animatedStyle,
				]}
			/>
		</View>
	);
};
export default LoadingBar;
