import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import UserType from "src/api/graphql/__generated__/enums/UserType";
import encodeURIArgs from "src/helpers/encodeURIArgs";
import log from "src/helpers/log";
import sanitizeCallback from "src/helpers/sanitizeCallback";
import _onValidAuthorization from "./_onValidAuthorization";
import { OnValidAuthorization } from "./types";

const onValidAuthorization: OnValidAuthorization = async (apolloClient, user, options) => {
	await _onValidAuthorization(apolloClient, user, options);

	const data = apolloClient.readQuery({
		query: GetClientProfileDocument,
	});
	let profile = data?.getClientProfile;
	// Local cache doesn't always sync with the very fast redirect, so we may need to fetch the user's data
	if (!profile || !profile.userId) {
		log.error("No data found in web local cache onValidAuthorization");
		const { data: newData } = await apolloClient.query({
			query: GetClientProfileDocument,
			fetchPolicy: "network-only",
		});
		profile = newData?.getClientProfile;
	}
	if (!profile) {
		log.error("No data found in web onValidAuthorization");
		return;
	}

	const urlParams = new URLSearchParams(window.location.search);
	const callback = sanitizeCallback(urlParams.get("callback"));
	const isOnboarding = profile.userType === UserType.Onboarding;

	const persistedCallbackParams = callback
		? `?callback=${callback}`
		: encodeURIArgs({
				callback: window.location.pathname + window.location.search,
			});

	if (isOnboarding) {
		if (window.location.pathname === "/login") return;
		if (
			window.location.pathname.match(/\/album\/[a-zA-Z0-9]+/) ||
			window.location.pathname.match(/\/album\/[a-zA-Z0-9]+\/photo/) ||
			window.location.pathname.match(/\/group\/[a-zA-Z0-9]+/)
		) {
			return;
		}
		window.location.assign("/login" + persistedCallbackParams);
	}
};

export default onValidAuthorization;
