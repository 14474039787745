import { create } from "zustand";

interface AuthStore {
	retryCount: number;
	phoneNumber: string | null;
	email: string | null;
	requestId: string | null;
	/**
	 *
	 * @returns the old retry count
	 */
	bumpRetryCount: () => number;
	setRequestId: (requestId: string) => void;
	setPhoneNumber: (phoneNumber: string) => void;
	setEmail: (email: string) => void;
	clear: () => void;
}

const useAuthStore = create<AuthStore>()((set, get) => ({
	retryCount: 0,
	requestId: null,
	phoneNumber: null,
	email: null,
	bumpRetryCount: () => {
		const prev = get().retryCount;
		set({ retryCount: prev + 1 });
		return prev;
	},
	setPhoneNumber: (phoneNumber) => {
		set({ phoneNumber });
	},
	setEmail: (email) => {
		set({ email });
	},
	setRequestId: (requestId) => {
		set({ requestId });
	},
	clear: () => {
		set({
			retryCount: 0,
			phoneNumber: null,
			email: null,
			requestId: null,
		});
	},
}));

export const getAuthStore = () => useAuthStore.getState();

export default useAuthStore;
