import { memo } from "react";
import { View } from "react-native";
import Loading from "src/components/Loading";
import { Image } from "src/swsh-native";
import { UploadDownloadInfo } from "../types";

const UploadDownloadImagePreview = ({
	previewImage,
}: {
	previewImage: UploadDownloadInfo["previewImage"];
}) => {
	return (
		<View
			style={{
				width: 32,
				height: 32,
				borderRadius: 4,
				overflow: "hidden",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{previewImage && previewImage.url ? (
				<Image
					alt={`Preview of image`}
					// Don't use a key here -- allows for smoother blending
					src={previewImage.url}
					dim={{
						height: 32,
						width: 32,
					}}
					style={{
						width: "100%",
						height: "100%",
					}}
				/>
			) : (
				<Loading />
			)}
		</View>
	);
};
export default memo(UploadDownloadImagePreview);
