import { EnrichmentPlugin, Event, PluginType, ReactNativeConfig } from "@amplitude/analytics-types";
import log from "src/helpers/log";
import AmplitudeStore from "../AmplitudeStore";

class ExtraMetadataPlugin implements EnrichmentPlugin {
	name = "ExtraMetadataPlugin";
	type = PluginType.ENRICHMENT as const;
	config?: ReactNativeConfig;

	async setup(config: ReactNativeConfig): Promise<undefined> {
		this.config = config;
		return;
	}

	async execute(event: Event): Promise<Event> {
		const { data, prefixes } = AmplitudeStore.getState();
		const metadata: Record<string, any> = {};

		for (const key in data) {
			metadata[`_${key}`] = data[key];
		}

		for (const key in metadata) {
			if (key in (event.event_properties || {})) {
				log.warn({ key }, `ExtraMetadataPlugin: Key collision`);
			}
		}

		if (prefixes.size > 0) {
			event.event_type = `_${Array.from(prefixes).sort().join("_")}_${event.event_type}`;
		}

		event.event_properties = {
			...metadata,
			...event.event_properties,
		};
		return event;
	}
}
export default ExtraMetadataPlugin;
