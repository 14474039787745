import { setContext } from "@apollo/client/link/context";
import graphQLHashVersion from "src/shared/constants/graphQLHashVersion";

const signatureMiddleware = setContext(async ({ query }, { headers }) => {
	const hash = query
		? "__meta__" in query
			? (query.__meta__ as any).hash
			: undefined
		: undefined;

	return {
		headers: {
			...headers,
			"x-operation-version": graphQLHashVersion,
			"x-operation-hash": hash,
		},
	};
});

export default signatureMiddleware;
