import { useCallback, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import UploadDownloadToastView from "../UploadDownloadToastView";
import { UploadDownloadToastBalancerProps } from "./types";

const UploadDownloadToastBalancer = ({
	downloadInfo,
	uploadInfo,
	onOpenChange,
	onFocusChange,
}: UploadDownloadToastBalancerProps) => {
	const downloadIsOpen = useRef(false);
	const uploadIsOpen = useRef(false);
	const [downloadIsMini, setDownloadIsMini] = useState(false);
	const [uploadIsMini, setUploadIsMini] = useState(false);

	useEffect(() => {
		onFocusChange?.(!(downloadIsMini || uploadIsMini));
	}, [downloadIsMini, onFocusChange, uploadIsMini]);
	const handleDownloadMiniToggle = useCallback(() => {
		setDownloadIsMini((prev) => !prev);
	}, []);
	const handleUploadMiniToggle = useCallback(() => {
		setUploadIsMini((prev) => !prev);
	}, []);

	const handleSyncHasOpen = useCallback(() => {
		onOpenChange?.(downloadIsOpen.current || uploadIsOpen.current);
	}, [onOpenChange]);

	const handleDownloadOnAppear = useCallback(() => {
		downloadIsOpen.current = true;
		handleSyncHasOpen();
	}, [handleSyncHasOpen]);
	const handleDownloadOnDisappear = useCallback(() => {
		downloadIsOpen.current = false;
		handleSyncHasOpen();
	}, [handleSyncHasOpen]);

	const handleUploadOnAppear = useCallback(() => {
		uploadIsOpen.current = true;
		handleSyncHasOpen();
	}, [handleSyncHasOpen]);

	const handleUploadOnDisappear = useCallback(() => {
		uploadIsOpen.current = false;
		handleSyncHasOpen();
	}, [handleSyncHasOpen]);

	return (
		// Wrapper contain exists to avoid scrolling
		// The toast items don't disappear they just slide off the screen
		<View
			pointerEvents="box-none"
			style={{
				position: "absolute",
				width: "100%",
				height: "100%",
				overflow: "hidden",
			}}
		>
			<UploadDownloadToastView
				{...downloadInfo}
				onAppear={handleDownloadOnAppear}
				onDisappear={handleDownloadOnDisappear}
				onMiniToggle={handleDownloadMiniToggle}
				isMini={downloadIsMini}
			/>
			<UploadDownloadToastView
				{...uploadInfo}
				onAppear={handleUploadOnAppear}
				onDisappear={handleUploadOnDisappear}
				onMiniToggle={handleUploadMiniToggle}
				isMini={uploadIsMini}
			/>
		</View>
	);
};
export default UploadDownloadToastBalancer;
