import { useMemo } from "react";
import { ViewStyle } from "react-native";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";

const useSearchModalDim = () => {
	const { device } = useDevice();
	const dim = useDim();

	const modalDim = useMemo<Pick<ViewStyle, "width" | "height">>(() => {
		if (device === "mobile") {
			return {
				width: "100%",
				height: "100%",
			};
		} else {
			return {
				width: Math.min(500, dim.width),
				height: Math.min(730, dim.height),
			};
		}
	}, [device, dim.height, dim.width]);

	return modalDim;
};
export default useSearchModalDim;
