import { FieldPolicy } from "@apollo/client";
import { GetGatheringsMinimalQuery } from "src/api/graphql/__generated__/graphql";
import mergeCursor from "../helpers/mergeCursor";
import sortKeySortFn from "../helpers/sortKeySortFn";

type QueryOptions = GetGatheringsMinimalQuery["getGatherings"];
const cacheQueryGetGatherings: FieldPolicy<QueryOptions | null> = {
	keyArgs: (args) => {
		const input = args?.input ?? {};
		let key = "";
		if (input?.key) {
			key += `k:${input.key}#`;
		}
		if (input?.relationship) {
			key += `r:${input.relationship}#`;
		}
		if (input?.search && input.search.length > 0) {
			key += `s:${input.search}#`;
		}
		return key;
	},
	merge: (existing, incoming, { readField }) => {
		const seenIds = new Set<string>();
		const newPayload = [];
		for (const gathering of (existing?.payload ?? []).concat(incoming?.payload ?? [])) {
			const gatheringId = readField<string>("gatheringId", gathering);
			if (gatheringId && !seenIds.has(gatheringId)) {
				newPayload.push(gathering);
				seenIds.add(gatheringId);
			}
		}

		newPayload.sort(
			sortKeySortFn(readField, {
				key: "sortKeyGetGatherings",
			}),
		);

		return {
			cursor: mergeCursor(existing, incoming, { readField }),
			payload: newPayload,
		};
	},
};
export default cacheQueryGetGatherings;
