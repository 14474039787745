"use client";
import { memo, useCallback, useRef } from "react";
import { View } from "react-native";
import useKeyboardListener from "src/hooks/useKeyboardListener";
import useStyle from "src/hooks/useStyle";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import { color } from "src/styles";
import { FixedPosition } from "src/styles/position";
import MDiv from "src/swsh-native/MDiv";
import popupCloseSelf from "src/swsh-native/Popups/helpers/popupCloseSelf";
import { PopupInfo } from "src/swsh-native/Popups/stores/usePopupStore";
import WebPopupContent from "./WebPopupContent.web";

const WebPopup = ({ popup }: { popup: PopupInfo }) => {
	const { theme } = useStyle();
	const renderTime = useRef(getCurTime_MS());

	const handleCancel = useCallback(() => {
		let hasCancel = false;
		if (popup.webEscapeBehavior) {
			popup.webEscapeBehavior();
			hasCancel = true;
		}
		for (const button of popup.buttons ?? []) {
			if (button.style === "cancel") {
				button.onPress?.();
				hasCancel = true;
			}
		}
		if (hasCancel) {
			popupCloseSelf(popup);
		}
	}, [popup]);

	useKeyboardListener({
		Escape: handleCancel,
	});

	return (
		<>
			<MDiv
				onClick={() => {
					// There's some weirdness on some platforms where this gets called from the click which invoked the web popup
					if (getCurTime_MS() - renderTime.current < 200) return;
					handleCancel();
				}}
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
				}}
				exit={{
					opacity: 0,
				}}
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					backgroundColor: color.BackgroundOverlay[theme],
					zIndex: 999,
				}}
			/>
			<View
				testID="Popup"
				pointerEvents="box-none"
				style={{
					position: FixedPosition,
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					justifyContent: "center",
					alignItems: "center",
					zIndex: 1000,
				}}
			>
				<WebPopupContent key={popup.id} popup={popup} />
			</View>
		</>
	);
};
export default memo(WebPopup);
