import { ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

const randomFailureMiddleware = new ApolloLink((operation, forward) => {
	const definition = getMainDefinition(operation.query);
	if (definition.kind === "OperationDefinition" && definition.operation !== "subscription") {
		if (Math.random() < 0.5) {
			throw new Error("Random failure");
		}
	}
	return forward(operation);
});

export default randomFailureMiddleware;
