import type { Variants } from "framer-motion";
import { m } from "framer-motion";
import cursor from "src/constants/cursor";
import { AnimatedHamburgerProps } from "./types";

const pathVariants = {
	open: {
		pathLength: 0,
	},
	closed: {
		pathLength: 1,
	},
} satisfies Variants;
const l1Variants = {
	open: {
		x1: 2,
		y1: 2,
		x2: 18,
		y2: 18,
	},
	closed: {
		x1: 2,
		y1: 4,
		x2: 18,
		y2: 4,
	},
} satisfies Variants;
const l2Variants = {
	open: {
		x1: 2,
		y1: 18,
		x2: 18,
		y2: 2,
	},
	closed: {
		x1: 2,
		y1: 16,
		x2: 18,
		y2: 16,
	},
} satisfies Variants;

const AnimatedHamburger = ({ isOpen, onPress, size = 24, testID }: AnimatedHamburgerProps) => {
	return (
		<m.svg
			data-testid={testID}
			initial="closed"
			animate={isOpen ? "open" : "closed"}
			width="1em"
			height="1em"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onPress}
			style={{
				cursor: onPress ? cursor.pointer : cursor.default,
				width: size,
				height: size,
			}}
		>
			<m.line variants={l2Variants} stroke="white" strokeWidth="2" strokeLinecap="round" />
			<m.path
				variants={pathVariants}
				transition={{
					duration: 0.1,
				}}
				d="M9.8 10H2"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<m.path
				variants={pathVariants}
				transition={{
					duration: 0.1,
				}}
				d="M11.1 10H18"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<m.line variants={l1Variants} stroke="white" strokeWidth="2" strokeLinecap="round" />
		</m.svg>
	);
};
export default AnimatedHamburger;
