import { ApolloClient } from "@apollo/client";
import { getAssetPersist } from "src/api/asset-persist/stores/useAssetPersist";
import { UploadAssetInfo } from "src/api/asset-persist/types";
import GatheringNumPhotosFragmentFragmentDoc from "src/api/graphql/__generated__/documents/GatheringNumPhotosFragmentFragmentDoc";
import PhotoVariant from "src/api/graphql/__generated__/enums/PhotoVariant";
import log from "src/helpers/log";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import persistOptimisticGraphQLBehavior from "../../persistOptimisticGraphQLBehavior";
import { GraphQLUploadPhotosMutation } from "../documents/GraphQLUploadPhotosDocument/types";
import uploadAsset from "./uploadAsset";

const processUploadPhotosBatch = async (
	data: GraphQLUploadPhotosMutation | null | undefined,
	{
		apolloClient,
		imageLookup,
	}: {
		apolloClient: ApolloClient<object>;
		imageLookup: Record<string, UploadAssetInfo>;
	},
) => {
	const { updateUploadAsset } = getAssetPersist();
	let numFailed = 0;

	const uploadPhotos = data?.uploadPhotos ?? [];

	const batchedOptimisticData: Record<
		string,
		{
			numPhotos: number;
		}
	> = {};

	await Promise.all(
		uploadPhotos.map(async (photo) => {
			const { success, didUpload } = await uploadAsset(imageLookup, photo);
			if (!success) {
				numFailed++;
			}
			if (success && didUpload && photo.localPhotoId) {
				const localImageInfo = imageLookup[photo.localPhotoId];
				const hasImage =
					photo.variant === PhotoVariant.Image ||
					photo.variant === PhotoVariant.LivePhoto;
				const hasVideo =
					photo.variant === PhotoVariant.Video ||
					photo.variant === PhotoVariant.LivePhoto;
				updateUploadAsset({
					localPhotoId: photo.localPhotoId,
					uploadState: "Uploaded",
					stateUpdatedAt: getCurTime_S(),
					uploadedPhotoInfo: {
						photoId: photo.photoId,
						url: hasImage ? (photo.readUrl?.url ?? null) : null,
						videoUrl: hasVideo ? (photo.readUrl?.videoUrl ?? null) : null,
						optimisticOptimizedUrl: photo.readUrl?.optimisticOptimizedUrl ?? null,
					},
				});
				if (!localImageInfo) {
					log.error(
						{
							photoId: photo.photoId,
							localPhotoId: photo.localPhotoId,
						},
						"Could not find localImageInfo",
					);
				} else {
					if (localImageInfo.gatheringId) {
						const existing = batchedOptimisticData[localImageInfo.gatheringId];
						batchedOptimisticData[localImageInfo.gatheringId] = {
							numPhotos: (existing?.numPhotos ?? 0) + 1,
						};
					}
					persistOptimisticGraphQLBehavior(apolloClient, {
						url: hasImage ? (photo.readUrl?.url ?? null) : null,
						localImageUri: localImageInfo.uriInfo.image?.uri ?? null,
						videoUrl: hasVideo ? (photo.readUrl?.videoUrl ?? null) : null,
						localVideoUri: localImageInfo.uriInfo.video?.uri ?? null,
						optimisticOptimizedUrl: photo.readUrl?.optimisticOptimizedUrl ?? null,
						photoId: photo.photoId,
						gatheringId: localImageInfo.gatheringId,
						isThumbnail: localImageInfo.isThumbnail,
					});
				}
			}
		}),
	);
	for (const [gatheringId, { numPhotos }] of Object.entries(batchedOptimisticData)) {
		const cachedNumPhotos = apolloClient.cache.readFragment({
			fragment: GatheringNumPhotosFragmentFragmentDoc,
			id: apolloClient.cache.identify({
				__typename: "Gathering",
				gatheringId,
			}),
		});
		apolloClient.cache.writeFragment({
			fragment: GatheringNumPhotosFragmentFragmentDoc,
			data: {
				__typename: "Gathering",
				gatheringId,
				numPhotos: (cachedNumPhotos?.numPhotos ?? 0) + numPhotos,
			},
		});
	}

	return { numFailed };
};
export default processUploadPhotosBatch;
