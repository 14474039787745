import {
	ApolloClient,
	ApolloProvider,
	ApolloQueryResult,
	NormalizedCacheObject,
} from "@apollo/client";
import { ReactNode, useEffect, useRef, useState } from "react";
import { getApolloClient } from "src/api/graphql/apolloClient";
import onValidAuthorization from "src/api/graphql/onValidAuthorization";

import forceLogout from "src/api/auth/forceLogout";
import hasLoggedInCredentials from "src/api/auth/helpers/hasLoggedInCredentials";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import { GetClientProfileQuery } from "src/api/graphql/__generated__/graphql";
import { curBackend } from "src/config";
import log from "src/helpers/log";
import { getGlobalStore, setGlobalStore } from "src/stores/useGlobalStore";

/**
 * Checks for existing cached profile, otherwise fetches it
 */
const checkProfile = async (
	apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<ApolloQueryResult<GetClientProfileQuery>["data"] | undefined> => {
	const cacheData = apolloClient.readQuery({
		query: GetClientProfileDocument,
	});
	if (cacheData && Object.keys(cacheData).length !== 0) {
		return cacheData;
	}
	const networkRes = await apolloClient.query({
		query: GetClientProfileDocument,
		fetchPolicy: "network-only",
	});
	return networkRes?.data;
};

export const loadApolloClient = async () => {
	const [apolloClient, loggedIn] = await Promise.all([
		getApolloClient(),
		hasLoggedInCredentials(),
	]);
	const { curBackend: existingBackend } = getGlobalStore();

	if (loggedIn) {
		if (existingBackend !== curBackend) {
			forceLogout();
			setGlobalStore({ curBackend });
			return apolloClient;
		}
		try {
			const data = await checkProfile(apolloClient);
			if (data?.getClientProfile) {
				await onValidAuthorization(apolloClient, data.getClientProfile);
				return apolloClient;
			}
		} catch (err) {
			log.error({ err }, "Error checking profile");
		}
		// In event of failure, it just pretends not logged in
	}

	// Could not get authenticated nor cached profile, so ensure logged out
	setGlobalStore({ loggedIn: false, clientId: null, userType: null });
	await apolloClient.clearStore();

	return apolloClient;
};
const GraphQLProvider = ({ children }: { children: ReactNode }) => {
	const [isReady, setIsReady] = useState(false);
	const apolloClient = useRef<ApolloClient<object> | null>(null);

	useEffect(() => {
		loadApolloClient()
			.then((client) => {
				apolloClient.current = client;
				setGlobalStore({ apolloClient: client });
				setIsReady(true);
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	if (!isReady) return null;
	if (!apolloClient.current) throw new Error("ApolloClient is null");

	return <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>;
};
export default GraphQLProvider;
