import { ComponentProps, ReactNode } from "react";
import { AnimatedMenuRoute } from "src/components/AnimatedMenu/types";
import ProfilePhotoQualityHint from "src/components/ProfilePictureUploadWrapper/components/ProfilePhotoQualityHint";
import { SharePayloadProp } from "src/components/ShareMenu";
import CommonPrefetchedData from "src/components/ShareMenu/helpers/PrefetchedData/CommonPrefetchedData";
import HamburgerNavModal from "src/screens/HamburgerNavModal";
import { ImageProps } from "src/swsh-native";
import lazy from "src/swsh-native/lazy";
import { ModalConfig } from "../types";

const ShareModal = lazy(() => import("src/screens/(share)/ShareModal"), {
	loading: () => null,
});
const SaveTutorialModal = lazy(() => import("src/screens/(share)/SaveTutorialModal"));
const ImageCropModal = lazy(() => import("src/screens/ImageCropModal"), {
	// ImageCropModal is a FullEmpty modal that should be preloaded. Having a skeleton here messes up the way preloading looks.
	loading: () => null,
});

export type ShareModalPreviewProps = {
	variant: "Image";
	src: ImageProps["src"];
	alt: ImageProps["alt"];
	dim?: ImageProps["dim"] | ((args: { width: number; height: number }) => ImageProps["dim"]);
	contentFit?: ImageProps["contentFit"];
	/**
	 * @default radiusMedium
	 */
	borderRadius?: number;
};
export type ShareModalProps = {
	data: SharePayloadProp["data"] | Promise<SharePayloadProp["data"]>;
	onComplete?: () => void;
	closeVariant: "Close" | "Skip";
	header: {
		title: string;
		subTitle?: string;
	};
	preview?: ShareModalPreviewProps | Promise<ShareModalPreviewProps | undefined>;
};

type GlobalModal = {
	Share: {
		props: ShareModalProps;
		defaultProps: {
			header: {
				title: string;
				subTitle?: string;
			};
			closeVariant: "Close" | "Skip";
		};
	};
	SaveTutorialModal: {
		props: {
			data: CommonPrefetchedData[];
			onComplete?: () => void;
			saveVariant?: "image" | "video" | "imageAndVideo";
		};
	};
	ImageCropModal: {
		props: {
			/**
			 * The image URI to be cropped. If null or resolved to null, the modal will close.
			 */

			imageUri: string | null | Promise<string | null>;
			renderCropHint?: (props: ComponentProps<typeof ProfilePhotoQualityHint>) => ReactNode;
			onComplete?: (uri: string) => Promise<void> | void;
			onCancel?: () => void;
			onError?: (error: Error) => void;
		};
	};
	HamburgerNav: {
		props: {
			onClose: () => void;
			routes: AnimatedMenuRoute[];
		};
	};
};

export default GlobalModal;

export const GlobalModalConfig = {
	Share: {
		component: ShareModal,
		variant: "Blur",
		defaultProps: {
			header: {
				title: "Share",
				subTitle: "Looking good and ready to share",
			},
			closeVariant: "Close",
		},
	},
	SaveTutorialModal: {
		component: SaveTutorialModal,
		variant: "Tall",
	},
	ImageCropModal: {
		component: ImageCropModal,
		variant: "FullEmpty",
		allowClose: false,
	},
	HamburgerNav: {
		component: HamburgerNavModal,
		variant: "FullFull",
		allowClose: false,
	},
} satisfies ModalConfig<GlobalModal>;
