import { FieldPolicy } from "@apollo/client";
import { PhotosCursor } from "src/api/graphql/__generated__/graphql";

/**
 * We actually don't want to merge the top photos, since any given photo might be removed
 * from the top photos list at any time. We just want to replace the existing top photos
 *
 * We also never paginate this field
 */
const cacheGatheringPhotos: FieldPolicy<PhotosCursor | null> = {
	keyArgs: false,
	merge: (existing, incoming) => {
		return incoming ?? existing ?? null;
	},
};
export default cacheGatheringPhotos;
