import useDevice from "src/hooks/useDevice";
import { paddingMedium } from "src/styles/spacing";
import { MDiv } from "src/swsh-native";
import AnimatedMenuItem from "./components/AnimatedMenuItem.web";
import { AnimatedMenuProps } from "./types";

const AnimatedMenu = ({ routes, onPress, containerStyle }: AnimatedMenuProps) => {
	const { device } = useDevice();
	return (
		<MDiv
			style={[
				{
					marginTop: "auto",
					marginBottom: "auto",
					alignItems: "center",
					rowGap: paddingMedium,
				},
				containerStyle,
			]}
		>
			{routes.map((route, i) => (
				<AnimatedMenuItem
					key={route.href}
					route={route}
					onPress={onPress}
					index={i}
					device={device}
				/>
			))}
		</MDiv>
	);
};
export default AnimatedMenu;
