import { paddingSmall } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import { LabeledTextInputProps } from "../types";

const TextInputLabel = ({
	label,
	required,
	sizeVariant,
}: {
	label: string | undefined;
	required?: boolean;
	sizeVariant: LabeledTextInputProps["sizeVariant"];
}) => {
	return (
		<Text
			xs
			color="TextLight"
			style={[
				{
					paddingBottom: paddingSmall,
					textTransform: "uppercase",
				},
				sizeVariant === "Small" && { fontSize: 10 },
			]}
		>
			{label}
			{required && (
				<Text color="Alert" style={{ paddingHorizontal: paddingSmall }}>
					*
				</Text>
			)}
		</Text>
	);
};
export default TextInputLabel;
