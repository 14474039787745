export const S_TO_MS = 1000;
export const MIN_TO_MS = 60 * S_TO_MS;
export const HOUR_TO_MS = 60 * MIN_TO_MS;
export const DAY_TO_MS = 24 * HOUR_TO_MS;
export const WEEK_TO_MS = 7 * DAY_TO_MS;
export const MONTH_TO_MS = 30 * DAY_TO_MS;
export const YEAR_TO_MS = 365 * DAY_TO_MS;

export const MIN_TO_S = 60;
export const HOUR_TO_S = 60 * MIN_TO_S;
export const DAY_TO_S = 24 * HOUR_TO_S;
export const MONTH_TO_S = 30 * DAY_TO_S;
export const YEAR_TO_S = 12 * MONTH_TO_S;

export const ONLINE_INTERVAL_TIME_S = 60 * 5; // 5 minutes
