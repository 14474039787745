import { getAssetPersist } from "../stores/useAssetPersist";
import { DownloadAssetInfo } from "../types";

const deleteDownloadAsset = (
	image: string | DownloadAssetInfo,
	{
		didFail,
	}: {
		didFail?: boolean;
	} = {},
) => {
	const imageId = typeof image === "string" ? image : image.assetId;
	const store = getAssetPersist();
	if (didFail) {
		store.updateDownloadAsset({
			assetId: imageId,
			didFail,
		});
		setTimeout(() => {
			store.deleteDownloadAsset(imageId);
		}, 100);
	} else {
		store.deleteDownloadAsset(imageId);
	}
};
export default deleteDownloadAsset;
