import { initFeatureControls } from "src/api/feature/featureControls";
import { create } from "zustand";

type MaybeUndefined<T> = {
	[P in keyof T]: T[P] | undefined;
};

type FeatureStore = MaybeUndefined<ReturnType<typeof initFeatureControls>>;
const useFeatureStore = create<FeatureStore>((set) => {
	const blank = initFeatureControls("blank");
	const blankValues: Partial<FeatureStore> = {};
	for (const key in blank) {
		blankValues[key] = undefined;
	}
	return blankValues as FeatureStore;
});
export default useFeatureStore;
