import { ApolloClient } from "@apollo/client";
import { getAssetPersist } from "src/api/asset-persist/stores/useAssetPersist";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import mmkv from "src/api/mmkv";
import log from "src/helpers/log";
import useGlobalStore from "src/stores/useGlobalStore";

const sharedClearCache = async (apolloClient: ApolloClient<any>) => {
	log.info("Clearing cache");
	await Promise.all([apolloClient.clearStore(), getAssetPersist().clear()]);
	mmkv.clearAll();
	await Promise.all([
		apolloClient.query({
			query: GetClientProfileDocument,
			fetchPolicy: "network-only",
		}),
		useGlobalStore.persist.rehydrate(),
	]);
};
export default sharedClearCache;
