import log from "./log";

export const consoleDev = (...args: any[]) => {
	if (__DEV__) {
		// @ts-ignore
		log.info(...args);
	}
};
export const consoleDevDuration = (str: string, diff: number) => {
	if (__DEV__) {
		consoleDev(`${str.padStart(40)}: ${Math.round(diff)}ms`);
	}
};
export const consoleDevPerformanceDiff = (str: string, startTime: number) => {
	if (__DEV__) {
		consoleDevDuration(str, performance.now() - startTime);
	}
};
export const consoleDevPerformanceWrapper = async <T>(str: string, fn: () => Promise<T>) => {
	if (__DEV__) {
		const t = performance.now();
		const res = await fn();
		consoleDevPerformanceDiff(str, t);
		return res;
	} else {
		return await fn();
	}
};
