import useGlobalStore from "src/stores/useGlobalStore";

export const getClientId = () => {
	const clientId = useGlobalStore.getState().clientId;
	if (!clientId) {
		throw new Error("clientId is not set");
	}
	return clientId;
};
export const useClientId = () => {
	const clientId = useGlobalStore((state) => state.clientId);
	if (!clientId) {
		throw new Error("clientId is not set");
	}
	return clientId;
};
export const getLoggedIn = () => useGlobalStore.getState().loggedIn;
