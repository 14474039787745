import { useEffect, useRef } from "react";
import { appStateVar } from "src/api/graphql/variables";
import useClientUpdate from "src/hooks/useClientUpdate";
import { ONLINE_INTERVAL_TIME_S, S_TO_MS } from "src/shared/constants/constants";

const useClientOnlineInterval = () => {
	const clientUpdate = useClientUpdate();
	const clientUpdateRef = useRef(clientUpdate);
	useEffect(() => {
		clientUpdateRef.current = clientUpdate;
	}, [clientUpdate]);

	const handlePing = useRef(async () => {
		if (appStateVar() === "active") {
			await clientUpdateRef.current({
				clientOnline: true,
			});
		}
	});

	// Regularly pings the server to keep the client online
	useEffect(() => {
		handlePing.current();
		const interval = setInterval(
			async () => {
				handlePing.current();
			},
			S_TO_MS * (ONLINE_INTERVAL_TIME_S - 15),
		);
		return () => clearInterval(interval);
	}, []);
};
export default useClientOnlineInterval;
