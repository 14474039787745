import { FieldPolicy } from "@apollo/client";
import { GetCommunitiesMinimalQuery } from "src/api/graphql/__generated__/graphql";
import mergeCursor from "../helpers/mergeCursor";
import sortKeySortFn from "../helpers/sortKeySortFn";

type QueryOptions = GetCommunitiesMinimalQuery["getCommunities"];
const cacheQueryGetCommunities: FieldPolicy<QueryOptions | null> = {
	keyArgs: (args) => {
		const input = args?.input ?? {};
		let key = "";
		if (input?.search && input.search.length > 0) {
			key += `s:${input.search}#`;
		}
		return key;
	},
	merge: (existing, incoming, { readField }) => {
		const seenIds = new Set<string>();
		const newPayload = [];
		for (const community of (existing?.payload ?? []).concat(incoming?.payload ?? [])) {
			const communityId = readField<string>("communityId", community);
			if (communityId && !seenIds.has(communityId)) {
				newPayload.push(community);
				seenIds.add(communityId);
			}
		}

		newPayload.sort(
			sortKeySortFn(readField, {
				key: "sortKeyGetCommunities",
			}),
		);

		return {
			cursor: mergeCursor(existing, incoming, { readField }),
			payload: newPayload,
		};
	},
};
export default cacheQueryGetCommunities;
