import { track } from "@amplitude/analytics-react-native";
import { useEffect, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import { Directions, Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";
import { amp } from "src/api/amplitude";
import BottomPopInMenu from "src/components/BottomPopInMenu";
import Loading from "src/components/Loading";
import useSyncRef from "src/hooks/useSyncRef";
import IconCheck from "src/icons/IconCheck";
import IconMinimize from "src/icons/IconMinimize";
import { paddingLarge, paddingMedium, paddingSmall, paddingXXL } from "src/styles/spacing";
import { BlurView, Text } from "src/swsh-native";
import LoadingBar from "../LoadingBar";
import UploadDownloadImagePreview from "./components/UploadDownloadImagePreview";
import { UploadDownloadToastViewProps } from "./types";

const SmallIconSize = 12;
const UploadDownloadToastView = ({
	isComplete = false,
	numComplete,
	numTotal,
	extraBottom,
	onAppear,
	onDisappear,
	variant,
	onClear,
	previewImage,
	Icon,
	completionCopy,
	progressCopy,
	isMini,
	onMiniToggle,
}: UploadDownloadToastViewProps) => {
	const [dismissed, setDismissed] = useState(true);
	const textContent = `${numComplete} / ${numTotal}`;

	const timeout = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		// Close after a while if finished
		if (isComplete) {
			timeout.current = setTimeout(() => {
				onClear?.();
				setDismissed(true);
			}, 1500);
		}

		// Open if new images
		if (!isComplete && numTotal > 0) {
			setDismissed(false);
			if (timeout.current) {
				clearTimeout(timeout.current);
				timeout.current = null;
			}
		}
	}, [isComplete, numTotal, onClear]);

	useEffect(() => {
		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, []);

	const didRender = useRef(false);
	useEffect(() => {
		if (!didRender.current) {
			didRender.current = true;
			return;
		}
		if (dismissed) {
			onDisappear?.();
			track(amp[variant].toastClose);
		}
		if (!dismissed) {
			onAppear?.();
			track(amp[variant].toastOpen);
		}
	}, [dismissed, onAppear, onDisappear, variant]);

	const onMiniToggleRef = useSyncRef(onMiniToggle);
	const { mainGesture, miniGesture } = useMemo(() => {
		const toggleMini = () => {
			onMiniToggleRef.current?.();
		};
		const createGesture = () => {
			return Gesture.Race(
				Gesture.Fling()
					.direction(Directions.DOWN)
					.onEnd(() => {
						toggleMini();
					})
					.runOnJS(true),
				Gesture.Tap()
					.onEnd(() => {
						toggleMini();
					})
					.runOnJS(true),
			);
		};
		return {
			mainGesture: createGesture(),
			miniGesture: createGesture(),
		};
	}, [onMiniToggleRef]);
	const showMain = !dismissed && !isMini && numTotal > 0;
	const showMini = !dismissed && isMini && numTotal > 0;

	return (
		<>
			<BottomPopInMenu show={showMain} extraBottom={extraBottom}>
				<GestureDetector gesture={mainGesture}>
					<BlurView
						testID={showMain ? `PopUp-${variant}` : undefined}
						style={{
							flexDirection: "row",
							paddingVertical: paddingLarge,
							paddingHorizontal: paddingXXL,
							borderRadius: 48,
							overflow: "hidden",
							width: 280 + 6 * Math.max(0, textContent.length - 16),
							maxWidth: "100%",
							alignItems: "center",
							columnGap: paddingMedium,
						}}
						intensity="floating-menu"
					>
						<View
							style={{
								flex: 1,
							}}
						>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										columnGap: paddingMedium,
									}}
								>
									<Icon size={SmallIconSize} />
									<Text sm semibold>
										{textContent}
									</Text>
								</View>
								<Text
									style={{
										right: 0,
									}}
								>
									{progressCopy}
								</Text>
							</View>
							<LoadingBar
								numComplete={numComplete}
								numTotal={numTotal}
								style={{
									marginTop: paddingMedium,
								}}
							/>
						</View>
						<UploadDownloadImagePreview previewImage={previewImage} />
						<IconMinimize size={20} />
					</BlurView>
				</GestureDetector>
			</BottomPopInMenu>
			<BottomPopInMenu show={showMini} extraBottom={extraBottom} weight={10}>
				<GestureDetector gesture={miniGesture}>
					<BlurView
						testID={showMini ? `PopUp-${variant}-Mini` : undefined}
						style={{
							paddingVertical: paddingMedium,
							paddingHorizontal: paddingLarge,
							borderRadius: 48,
							width: Math.max(
								140 + 6 * Math.max(0, textContent.length - 16),
								70 + 8 * Math.max(0, completionCopy.length),
							),
							overflow: "hidden",
						}}
						intensity="floating-menu"
					>
						<Animated.View
							key={`${isComplete}`}
							entering={FadeIn}
							exiting={FadeOut}
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								columnGap: paddingSmall,
							}}
						>
							{isComplete ? (
								<>
									<Text sm semibold>
										{completionCopy}
									</Text>
									<IconCheck size={SmallIconSize} />
								</>
							) : (
								<>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											columnGap: paddingMedium,
										}}
									>
										<Icon size={SmallIconSize} />
										<Text sm semibold>
											{textContent}
										</Text>
									</View>
									<Loading size={SmallIconSize} />
								</>
							)}
						</Animated.View>
					</BlurView>
				</GestureDetector>
			</BottomPopInMenu>
		</>
	);
};
export default UploadDownloadToastView;
