import zustandMmkvStore from "src/api/mmkv/zustandMmkvStore";
import { Timestamp_S } from "src/shared/types/general";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

/**
 * Chuck anything in here that you want to persist
 */
interface PersistenceStore {
	gatheringInteractionData: Record<string, { lastViewedAt: Timestamp_S }>;
	clear: () => void;
}
const initialState: Pick<PersistenceStore, "gatheringInteractionData"> = {
	gatheringInteractionData: {},
};

const usePersistenceStore = create<PersistenceStore>()(
	persist(
		(set, get) => ({
			...initialState,
			clear: () => {
				set({
					...initialState,
				});
			},
		}),
		{
			name: "zustand-persistence-store",
			storage: createJSONStorage(() => zustandMmkvStore),
			partialize: (state) =>
				({
					gatheringInteractionData: state.gatheringInteractionData,
				}) satisfies Partial<PersistenceStore>,
		},
	),
);

export default usePersistenceStore;
