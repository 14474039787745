import { useQuery } from "@apollo/client";
import { ReactNode } from "react";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import UserType from "src/api/graphql/__generated__/enums/UserType";
import { AppOnlyModalConfig } from "src/modals/configs/AppOnlyModal";
import { InternalModalConfig } from "src/modals/configs/InternalModal";
import RootModalContainer from "src/modals/containers/RootModalContainer";
import useGlobalStore from "src/stores/useGlobalStore";
import NotificationProvider from "../NotificationProvider";
import useInternalHooks from "./hooks/useInternalHooks";
import useNativeInternalHooks from "./hooks/useNativeInternalHooks";

const InternalComponents = () => {
	useInternalHooks();
	useNativeInternalHooks();

	return (
		<>
			<RootModalContainer config={InternalModalConfig} />
			<RootModalContainer config={AppOnlyModalConfig} />
		</>
	);
};

/**
 * Doesn't actually wrap to avoid re-renders.
 */
const _InternalProvider = () => {
	// Avoiding using useClientProfile here since this is called outside of the main navigator
	const { data: { getClientProfile: profile } = {} } = useQuery(GetClientProfileDocument, {
		fetchPolicy: "cache-first",
	});

	if (!profile?.userType || profile.userType !== UserType.Full) {
		return null;
	}
	return (
		<>
			<NotificationProvider />
			<InternalComponents />
		</>
	);
};

const InternalProvider = ({ children }: { children?: ReactNode }) => {
	const isValidFullUser = useGlobalStore((state) => state.isValidFullUser());

	return (
		<>
			{children}
			{isValidFullUser && <_InternalProvider />}
		</>
	);
};
export default InternalProvider;
