"use client";
import { add, init } from "@amplitude/analytics-react-native";
import { AMPLITUDE_KEY } from "src/config";
import isTestUser from "src/constants/isTestUser";
import isClient from "src/helpers/isClient";
import ExtraMetadataPlugin from "./plugins/ExtraMetadataPlugin";

const configureAmplitude = () => {
	if (!isClient) return;
	init(AMPLITUDE_KEY, undefined, {
		optOut: isTestUser,
	});
	add(new ExtraMetadataPlugin());
};
export default configureAmplitude;
