import { identify, Identify, setUserId, track } from "@amplitude/analytics-react-native";
import { amp } from "src/api/amplitude";
import { initFeatureControls } from "src/api/feature/featureControls";
import sentry from "src/api/sentry";
import clientVersion from "src/constants/clientVersion";
import useFeatureStore from "src/stores/useFeatureStore";
import { getGlobalStore, GlobalStore, setGlobalStore } from "src/stores/useGlobalStore";
import onValidOpen from "../onValidOpen";
import { OnValidAuthorization } from "./types";

const _onValidAuthorization: OnValidAuthorization = async (
	apolloClient,
	user,
	{ promises = [] } = {},
) => {
	const { userId, userType } = user;
	const { getClientProfilePromise } = await onValidOpen(apolloClient);

	setUserId(userId);
	const partialGlobal: Partial<GlobalStore> = {
		loggedIn: true,
		clientId: userId,
	};
	// We don't want to overwrite userType if it's just not defined in the passed in user
	if (userType) partialGlobal.userType = userType;

	setGlobalStore(partialGlobal);
	getClientProfilePromise?.then((res) => {
		const newUserType = res.data?.getClientProfile?.userType;
		if (newUserType && getGlobalStore().userType !== newUserType) {
			setGlobalStore({ userType: newUserType });
		}
	});
	const featureState = initFeatureControls(userId);
	useFeatureStore.setState({
		...featureState,
	});

	const identifyObj = new Identify();
	identifyObj.set("feature", featureState);
	identifyObj.set("clientVersion", clientVersion);
	identify(identifyObj);

	track(amp.auth.login);
	sentry.setUser({ id: userId });
	await Promise.all(promises);
};

export default _onValidAuthorization;
