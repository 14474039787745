import { useMemo } from "react";
import { DimensionValue, LayoutChangeEvent } from "react-native";
import Touchable from "src/components/Touchable";
import useStyle from "src/hooks/useStyle";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { paddingXL } from "src/styles/spacing";
import { PopupButtonInfo } from "src/swsh-native/Popups/stores/usePopupStore";
import Text from "src/swsh-native/Text";

const WebPopupButton = ({
	buttonInfo: { style: buttonStyle = "default", text, isPreferred, onPress },
	onLayout,
	width,
	paddingHorizontal = paddingXL,
}: {
	buttonInfo: Omit<PopupButtonInfo, "onPress"> & {
		onPress: () => void;
	};
	onLayout?: (event: LayoutChangeEvent) => void;
	width?: DimensionValue;
	paddingHorizontal?: DimensionValue;
}) => {
	const { theme } = useStyle();
	const { borderColor, backgroundColor, textColor } = useMemo<{
		borderColor: string;
		backgroundColor: string;
		textColor: string;
	}>(() => {
		switch (buttonStyle) {
			case "default":
				return {
					borderColor: color.ButtonBackgroundContrast[theme],
					backgroundColor: color.ButtonBackgroundContrast[theme],
					textColor: color.TextMatch[theme],
				};
			case "cancel":
				return {
					borderColor: color.Border[theme],
					backgroundColor: color.BackgroundModal[theme],
					textColor: color.TextContrast[theme],
				};
			case "destructive":
				return {
					borderColor: color.Destructive[theme],
					backgroundColor: color.Destructive[theme],
					textColor: color.TextContrast[theme],
				};
			default:
				return enumNever(buttonStyle);
		}
	}, [buttonStyle, theme]);

	return (
		<Touchable
			onLayout={onLayout}
			onPress={onPress}
			containerStyle={{
				width,
				flex: width == null ? 1 : undefined,
				height: 40,
				borderRadius: 12,

				borderWidth: 1,
				borderColor,
				backgroundColor,

				shadowColor: "#000000",
				shadowOffset: {
					width: 0,
					height: 2,
				},
				shadowOpacity: 0.3,
				shadowRadius: 2.62,
				elevation: 4,

				justifyContent: "center",
				alignItems: "center",
			}}
			style={{
				alignItems: "center",
				paddingHorizontal,
			}}
		>
			<Text semibold={isPreferred} small color={textColor}>
				{text}
			</Text>
		</Touchable>
	);
};
export default WebPopupButton;
