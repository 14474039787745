import { FieldPolicy, Reference, StoreObject } from "@apollo/client";
import { Photo, PhotosCursor } from "src/api/graphql/__generated__/graphql";
import FaceFilterMode from "../../__generated__/enums/FaceFilterMode";
import GatheringPhotosFilter from "../../__generated__/enums/GatheringPhotosFilter";
import PhotoVariant from "../../__generated__/enums/PhotoVariant";
import mergeCursor from "../helpers/mergeCursor";
import sortKeySortFn from "../helpers/sortKeySortFn";

const cacheGatheringPhotos: FieldPolicy<PhotosCursor | null> = {
	keyArgs: (args) => {
		const input = args?.input ?? {};
		let key = `key:${input.key ?? "#"}#${input.filter ?? GatheringPhotosFilter.Active}`;
		const filterUserIds: string[] = input.filterUserIds ?? [];
		const filterUploaderIds: string[] = input.filterUploaderIds ?? [];
		const filterPhotoVariant: PhotoVariant[] = input.filterPhotoVariant ?? [];
		const filterLabels: string[] = input.filterLabels ?? [];
		const filterRestrictedLabels: string[] = input.filterRestrictedLabels ?? [];

		// Normalizing key args. Empty filters is effectively the same as no filters
		if (
			filterUserIds.length === 0 &&
			filterUploaderIds.length === 0 &&
			filterPhotoVariant.length === 0 &&
			filterLabels.length === 0 &&
			filterRestrictedLabels.length === 0
		) {
			return key;
		}

		const orderedFilterUserIds = [...(filterUserIds ?? [])].sort();
		const orderedFilterUploaderIds = [...(filterUploaderIds ?? [])].sort();
		const orderedFilterPhotoVariant = [...(filterPhotoVariant ?? [])].sort();
		const orderedFilterLabels = [...(filterLabels ?? [])].sort();
		const orderedFilterRestrictedLabels = [...(filterRestrictedLabels ?? [])].sort();

		// And missing filterMode is effectively the same as Or
		const filterMode = input.filterMode ?? FaceFilterMode.Or;
		// Concatenate and sort all filters
		key += `:filter:${filterMode}:${orderedFilterUserIds.join(",")}:${orderedFilterUploaderIds.join(",")}:${orderedFilterPhotoVariant.join(",")}:${orderedFilterLabels.join(",")}:${orderedFilterRestrictedLabels.join(",")}`;

		return key;
	},
	merge: (existing, incoming, { readField, mergeObjects, variables }) => {
		const payloadLookup: Record<string, StoreObject | Reference> = {};
		const retObj = {
			cursor: mergeCursor(existing, incoming, {
				readField,
			}),
			reverseCursor: mergeCursor(existing, incoming, {
				readField,
				reverse: true,
				key: "reverseCursor",
			}),
			payload: [] as Photo[],
		} satisfies PhotosCursor;
		const photos = ([] as Photo[])
			.concat(existing?.payload ?? [])
			.concat(incoming?.payload ?? []);

		// Populates payloadLookup with photos and merges duplicates
		for (const item of photos) {
			const photoId = readField<string>("photoId", item);
			if (!photoId) {
				// Likely means it was evicted from the cache
				continue;
			}
			const duplicate = payloadLookup[photoId];
			if (duplicate) {
				payloadLookup[photoId] = mergeObjects(duplicate, item);
			}
			payloadLookup[photoId] = item;
		}

		// Populates the return value
		// while maintaining the order of existing and incoming
		// also removes duplicates
		const addedPhotoIds = new Set<string>();
		for (const item of photos) {
			const photoId = readField<string>("photoId", item);
			if (!photoId) {
				// Likely means it was evicted from the cache
				continue;
			}
			if (addedPhotoIds.has(photoId)) continue;
			addedPhotoIds.add(photoId);
			const cur = payloadLookup[photoId];
			if (cur) {
				retObj.payload.push(cur as Photo);
			}
		}

		if (variables?.photosInput?.filter === GatheringPhotosFilter.ActiveCaptureSort) {
			// We want to use the sort from the server for the active capture sort
			return retObj;
		} else {
			// All other modes handle sorting client side (which should usually match server side sorting, except in cases of cache manipulations)
			retObj.payload.sort(
				sortKeySortFn(readField, {
					key: "sortKey",
				}),
			);
			return retObj;
		}
	},
};
export default cacheGatheringPhotos;
