import { RefObject } from "react";
import type { WebView } from "react-native-webview";
import CommunityJoinModal from "src/screens/(internal)/(RefCodeJoin)/CommunityJoinModal";
import WebViewModal from "src/screens/WebViewModal";
import GatheringJoinModal from "../../screens/(internal)/(RefCodeJoin)/GatheringJoinModal/GatheringJoinModal";
import { ModalConfig } from "../types";

type AppOnlyModal = {
	GatheringJoinModal: {};
	CommunityJoinModal: {};
	WebViewModal: {
		props: {
			url: string;
			onNavigationStateChange?: (props: {
				ref: RefObject<WebView>;
				url: string;
				canGoBack: boolean;
				canGoForward: boolean;
				closeModal: () => void;
			}) => void;
		};
	};
};

export default AppOnlyModal;

export const AppOnlyModalConfig = {
	GatheringJoinModal: {
		component: GatheringJoinModal,
		variant: "Full",
	},
	CommunityJoinModal: {
		component: CommunityJoinModal,
		variant: "Full",
	},
	WebViewModal: {
		component: WebViewModal,
		variant: "Full",
	},
} satisfies ModalConfig<AppOnlyModal>;
