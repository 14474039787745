import native from "src/constants/native";
import { Timestamp_MS } from "src/shared/types/general";
import { create } from "zustand";

interface SplashScreenStore {
	navigationReady: boolean;
	rootDidRender: boolean;
	forceSplashScreen: boolean;
	logoutInitializedAt: Timestamp_MS | null;
}

const useSplashScreenStore = create<SplashScreenStore>((set) => ({
	navigationReady: native ? false : true,
	rootDidRender: false,
	forceSplashScreen: false,
	logoutInitializedAt: null,
}));
export default useSplashScreenStore;
