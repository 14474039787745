export const DefaultRetryCodes = [429, 500, 502, 503, 504];
type FetchInput = NonNullable<Parameters<typeof fetch>[0]>;
interface FetchOptions extends Omit<RequestInit, "signal"> {
	/**
	 * Abort signal to cancel the request.
	 *
	 * If a function is provided, it will be called with the number of attempts and should return an AbortSignal.
	 *
	 * AbsortSignal is reused across failed requests. Meaning if you create an AbortSignal with timeout of 5 seconds, it'll allow for 5 seconds across all retries.
	 *
	 * Passing a function allows for each retry attempt to have a different AbortSignal. (ex: 5 seconds for each retry)
	 */
	signal?: AbortSignal | ((info: { numAttempts: number }) => AbortSignal) | undefined | null;
}
interface RequestInfo {
	/**
	 * Number of attempts made so far. Starts at 0.
	 */
	numAttempts: number;
}

const resolveOptions = (
	options: FetchOptions | undefined,
	info: RequestInfo,
): RequestInit | undefined => {
	if (!options) {
		return options;
	}
	if (typeof options.signal === "function") {
		return {
			...options,
			signal: options.signal(info),
		};
	}
	return options as RequestInit;
};
const createFetchWithRetry = ({
	statusCodes = DefaultRetryCodes,
	retries = 3,
	fetchFn = fetch,
	baseDelay = 40,
	maxDelay = 500,
	rewriteRequest,
	errorMessage = "Failed fetch with retry",
}: {
	statusCodes?: number[];
	retries?: number;
	fetchFn?: typeof fetch;
	/**
	 * Base delay between retries in milliseconds. Increased exponentially with each retry
	 *
	 * @default 40
	 */
	baseDelay?: number;
	/**
	 * Maximum delay between retries in milliseconds
	 *
	 * @default 500
	 */
	maxDelay?: number;
	rewriteRequest?: (
		url: FetchInput,
		options: FetchOptions | undefined,
		info: RequestInfo,
	) => { url: FetchInput; options: FetchOptions | undefined };
	/**
	 * Error message to throw if all retries fail
	 *
	 * @default "Failed fetch with retry"
	 */
	errorMessage?: string;
} = {}) => {
	const RetryCodes = new Set(statusCodes);
	let delay = baseDelay;
	const fetchWithRetry = async (url: FetchInput, options?: FetchOptions): Promise<Response> => {
		for (let attempt = 0; attempt < retries; attempt++) {
			try {
				const info: RequestInfo = { numAttempts: attempt };
				const { url: parsedUrl, options: parsedOptions } = rewriteRequest?.(
					url,
					options,
					info,
				) ?? { url, options };

				const response = await fetchFn(parsedUrl, resolveOptions(parsedOptions, info));
				if (RetryCodes.has(response.status)) {
					throw new Error(`${errorMessage}: ${response.status} ${response.statusText}`);
				}
				return response;
			} catch (error) {
				if (attempt >= retries - 1) {
					throw error;
				}
			}
			if (delay > 0) {
				await new Promise((resolve) => setTimeout(resolve, delay));
				delay = Math.min(delay * 1.5 + Math.random() * delay, maxDelay);
			}
		}
		throw new Error("Unreachable");
	};
	return fetchWithRetry;
};
export default createFetchWithRetry;
