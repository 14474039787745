import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

type Sortable<TKey extends string = "sortKey"> = {
	[key in TKey]?: string | null | undefined;
};
/**
 * Returns a sort function that sorts by the readField sortKey
 *
 * Lower sortKey values are sorted first
 */
const sortKeySortFn = <TKey extends string = "sortKey">(
	readField: ReadFieldFunction,
	{ key }: { key: TKey },
) => {
	return (a: Sortable<TKey>, b: Sortable<TKey>) => {
		const aSortKey = readField<string>(key, a);
		const bSortKey = readField<string>(key, b);
		if (!aSortKey && bSortKey) {
			return 1;
		}
		if (aSortKey && !bSortKey) {
			return -1;
		}
		// Is effectively !aSortKey && !bSortKey, just doing this for type safety
		if (!aSortKey || !bSortKey) {
			return 0;
		}
		if (aSortKey === bSortKey) {
			return 0;
		}
		return aSortKey < bSortKey ? -1 : 1;
	};
};
export default sortKeySortFn;
