import { track } from "@amplitude/analytics-react-native";
import { randomUUID } from "expo-crypto";
import { amp } from "src/api/amplitude";
import PhotoVariant from "src/api/graphql/__generated__/enums/PhotoVariant";
import isTestUser from "src/constants/isTestUser";
import getExtFromContentType from "src/helpers/fileHelpers/getExtFromContentType";
import log from "src/helpers/log";
import safeParseInt from "src/helpers/safeParseInt";
import { enumNever } from "src/shared/helpers/generalHelpers";
import FileMetadata from "src/types/FileMetadata";
import { DownloadAssetInfo, DownloadObject } from "../../../../types";
import deleteDownloadAsset from "../../../deleteDownloadAsset";

const retryCodes = new Set([429, 500, 502, 503, 504]);

const createClickA = (url: string, fileName: string, _blank?: boolean) => {
	const a = document.createElement("a");
	if (_blank) {
		a.target = "_blank";
	}
	a.href = url;
	a.setAttribute("download", fileName);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

const getMetadata = async (urlInfo: DownloadObject): Promise<FileMetadata> => {
	const metadata = await urlInfo.metadata?.();
	if (metadata) return metadata;
	const res = await fetch(urlInfo.url, {
		method: "HEAD",
	});
	if (!res.ok) throw new Error("Failed to fetch metadata");
	const contentType = res.headers.get("content-type") ?? "image/jpeg";
	const ext = getExtFromContentType(contentType);
	const contentDisposition = res.headers.get("content-disposition") ?? null;
	const contentLength = res.headers.get("content-length") ?? null;
	return {
		contentType,
		ext,
		contentDisposition,
		contentLength: safeParseInt(contentLength),
	};
};

const MB = 1024 * 1024;
const individualDownloadAsset = async (urlInfo: DownloadObject) => {
	const { ext, contentDisposition, contentLength } = await getMetadata(urlInfo);
	if (
		contentLength !== null &&
		contentLength > 5 * MB &&
		contentDisposition &&
		contentDisposition.includes("attachment") &&
		!isTestUser
	) {
		// This is much, much less memory intensive but downloads files individually
		createClickA(urlInfo.url, `${randomUUID()}.${ext}`, true);
		return {
			success: true,
		};
	} else {
		const res = await fetch(urlInfo.url, {
			method: "GET",
			cache: "force-cache",
		});
		if (!res.ok) {
			if (retryCodes.has(res.status)) {
				return {
					success: false,
				};
			}
			throw new Error(`Failed to download image: ${res.status}`);
		}

		const blob = await res.blob();
		const url = window.URL.createObjectURL(blob);
		createClickA(url, `${randomUUID()}.${ext}`);
		return {
			success: true,
		};
	}
};

const callIndividualDownloadAsset = async (asset: DownloadAssetInfo) => {
	const { variant } = asset;
	switch (variant) {
		case PhotoVariant.Image:
		case PhotoVariant.LivePhoto:
			return await individualDownloadAsset(asset.image);
		case PhotoVariant.Video:
			return await individualDownloadAsset(asset.video);
		default:
			return enumNever(variant);
	}
};

const downloadAsset = async (
	asset: DownloadAssetInfo,
): Promise<{
	success: boolean;
}> => {
	const t = performance.now();
	let success = true;
	try {
		const downloadRes = await callIndividualDownloadAsset(asset);
		if (downloadRes.success === false) {
			return {
				success: false,
			};
		}
		deleteDownloadAsset(asset);
		return {
			success: true,
		};
	} catch (err) {
		success = false;
		log.error({ err }, "Error downloading image");
		deleteDownloadAsset(asset, {
			didFail: true,
		});
		return {
			success: false,
		};
	} finally {
		track(amp.download.dataDownloadComplete, {
			duration: performance.now() - t,
			success,
			variant: asset.variant,
		});
	}
};
export default downloadAsset;
