import { ForwardedRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import Animated from "react-native-reanimated";
import PillButton from "src/components/PillButton";
import useAnimatedOpacityStyle from "src/hooks/useAnimatedOpacityStyle";
import useStyle from "src/hooks/useStyle";
import IconClose from "src/icons/IconClose";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { basicFont, largeFont, smallFont } from "src/styles/fonts";
import { paddingMedium, paddingSmall } from "src/styles/spacing";
import { Text, TextInput } from "src/swsh-native";
import { TextInputHandler } from "src/swsh-native/TextInput/TextInput";
import { LabeledTextInputHandler, LabeledTextInputProps } from "../types";
import LabelHeaderRow from "./LabelHeaderRow";
import ValidCheck from "./ValidCheck";

const LabeledTextInputWithProps = ({
	label,
	placeholder,
	characterLimit,
	showValidator,
	showCharacterLimit,
	validator,
	showClear,
	sizeVariant = "Medium",
	initialValue,
	onDebouncedChangeText,
	onValidChangeText,
	children,
	textInputStyle,
	value: _,
	parentRef,
	clearInitialValueOnFocus,
	prefix,
	required,
	testID,
	...textInputProps
}: Omit<LabeledTextInputProps, "style"> & {
	parentRef: ForwardedRef<LabeledTextInputHandler>;
}) => {
	const { theme } = useStyle();
	const [value, setValue] = useState<string>(initialValue ?? "");
	const textInputRef = useRef<TextInputHandler>(null);
	const handleDebouncedChangeText = useCallback<NonNullable<typeof onDebouncedChangeText>>(
		(text, extra) => {
			setValue(text);
			onDebouncedChangeText?.(text, extra);
			if (validator) {
				if (validator(text)) {
					onValidChangeText?.(text);
				}
			} else {
				onValidChangeText?.(text);
			}
		},
		[onDebouncedChangeText, onValidChangeText, validator],
	);
	useImperativeHandle(parentRef, () => ({
		clear: () => {
			textInputRef.current?.clear();
			handleDebouncedChangeText("", {});
		},
		setValue: (value: string) => {
			textInputRef.current?.setValue(value);
			handleDebouncedChangeText(value, {});
		},
		focus: () => {
			textInputRef.current?.focus();
		},
		blur: () => {
			textInputRef.current?.blur();
		},
	}));

	const animatedStyle = useAnimatedOpacityStyle(+(value.length > 0), {
		duration: 100,
	});

	const textInputFont = useMemo(() => {
		switch (sizeVariant) {
			case "Small":
				return smallFont;
			case "Medium":
				return basicFont;
			case "Large":
				return largeFont;
			default:
				return enumNever(sizeVariant);
		}
	}, [sizeVariant]);

	return (
		<>
			<LabelHeaderRow
				label={label}
				characterLimit={characterLimit}
				showCharacterLimit={showCharacterLimit}
				value={value}
				required={required}
				style={{
					paddingHorizontal: paddingSmall,
				}}
				sizeVariant={sizeVariant}
			/>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				{prefix &&
					(typeof prefix === "string" ? (
						<Text
							color="TextLight"
							small={sizeVariant === "Small"}
							basic={sizeVariant === "Medium"}
							large={sizeVariant === "Large"}
						>
							{prefix}
						</Text>
					) : (
						prefix
					))}
				<TextInput
					ref={textInputRef}
					{...textInputProps}
					defaultValue={initialValue}
					onDebouncedChangeText={handleDebouncedChangeText}
					placeholderTextColor={color.InputPlaceholder[theme]}
					style={[
						textInputFont,
						sizeVariant === "Large" && {
							fontWeight: "600",
						},
						{
							width: "100%",
							padding: paddingSmall,
							borderRadius: 4,
							flex: 1,
						},
						sizeVariant === "Small" && {
							paddingVertical: 0,
						},
						textInputStyle,
					]}
					placeholder={placeholder}
					testID={testID ? `${testID}-TextInput` : undefined}
				/>
				{showValidator && <ValidCheck value={value} validator={validator} />}
				{showClear && (
					<Animated.View style={animatedStyle}>
						<PillButton
							hitSlop={10}
							sizeVariant="xs"
							Icon={IconClose}
							testID={value.length > 0 ? "Clear" : undefined}
							containerStyle={{
								marginLeft: paddingMedium,
							}}
							onPress={() => {
								textInputRef.current?.clear();
								textInputRef.current?.focus();
								handleDebouncedChangeText("", {});
							}}
						/>
					</Animated.View>
				)}
			</View>
		</>
	);
};
export default LabeledTextInputWithProps;
