import log from "../log";

const getExtFromContentType = (contentType: string): string => {
	switch (contentType) {
		case "image/jpeg":
			return "jpg" as const;
		case "image/png":
			return "png" as const;
		case "image/gif":
			return "gif" as const;
		case "image/webp":
			return "webp" as const;
		case "image/svg+xml":
			return "svg" as const;
		case "image/bmp":
			return "bmp" as const;
		case "image/x-icon":
			return "ico" as const;
		case "image/tiff":
			return "tiff" as const;
		case "image/vnd.microsoft.icon":
			return "ico" as const;
		case "image/vnd.wap.wbmp":
			return "wbmp" as const;
		case "image/x-xbitmap":
			return "xbm" as const;
		case "image/heic":
			return "heic" as const;
		case "image/heif":
			return "heif" as const;
		case "video/mp4":
			return "mp4" as const;
		case "video/quicktime":
			return "qt" as const;
		case "video/mpeg":
			return "mpeg" as const;
		case "video/3gpp":
			return "3gp" as const;
		case "image/x-canon-cr2":
			return "cr2" as const;
		case "image/x-nikon-nef":
			return "nef" as const;
		case "image/x-sony-arw":
			return "arw" as const;
		case "image/x-adobe-dng":
			return "dng" as const;
		case "video/avi":
			return "avi" as const;
		case "video/x-ms-wmv":
			return "wmv" as const;
		case "video/mpeg":
			return "mpeg" as const;
		case "video/webm":
			return "webm" as const;
		case "video/x-flv":
			return "flv" as const;
		case "video/x-matroska":
			return "mkv" as const;

		default:
			log.error({ contentType }, "Unknown content type");
			if (contentType.startsWith("image/")) {
				return "jpg";
			}
			if (contentType.startsWith("video/")) {
				return "mp4";
			}
			return "jpg";
	}
};

export default getExtFromContentType;
