import type { Variants } from "framer-motion";
import RoundedButton from "src/components/RoundedButton";
import IconNavLink from "src/icons/IconNavLink";
import { paddingLarge } from "src/styles/spacing";
import { Link, MDiv, Text } from "src/swsh-native";
import { EXIT_ANIMATION_DURATION, SpacingBetweenMenuItems } from "../constants";
import { AnimatedMenuItemProps } from "./types";

const arrowDist = 4;
const NavLinkVariants = {
	default: {
		transform: "translateX(0px) translateY(0px)",
	},
	active: {
		transform: `translateX(${arrowDist}px) translateY(-${arrowDist}px)`,
	},
} satisfies Variants;

const textColor = "#FFFFFF";

const AnimatedMenuItem = ({
	route: { href, target, Icon, title, isButton, renderItem, hasArrow },
	onPress,
	index,
	device,
}: AnimatedMenuItemProps) => {
	return (
		<MDiv
			whileTap="active"
			whileHover="active"
			initial={{
				transform: "translateX(-50px)",
				opacity: 0,
			}}
			animate={{
				transform: "translateX(0px)",
				opacity: 1,
			}}
			transition={{
				delay: index * 0.1,
			}}
			exit={{
				opacity: 0,
				transition: {
					duration: EXIT_ANIMATION_DURATION,
				},
			}}
			onClick={() => onPress?.(href)}
			style={{
				paddingBottom: SpacingBetweenMenuItems,
			}}
		>
			{isButton ? (
				<RoundedButton
					href={href}
					target={target}
					colorVariant="Primary"
					sizeVariant="Large"
					Icon={Icon}
					textProps={{
						semiLarge: true,
						semibold: true,
					}}
				>
					{title}
				</RoundedButton>
			) : (
				<Link href={href} target={target}>
					<MDiv
						initial={{
							opacity: 0.5,
						}}
						whileHover={{
							opacity: 1,
						}}
						style={{
							flexDirection: "row",
							alignItems: "center",
							columnGap: paddingLarge,
						}}
					>
						{!!renderItem && renderItem()}
						{!!Icon && <Icon size={32} color={textColor} />}
						<Text
							semibold
							xl={device === "desktop"}
							lg={device === "mobile"}
							style={{
								flex: 1,
								color: textColor,
							}}
						>
							{title}
						</Text>
						{hasArrow && (
							<MDiv variants={NavLinkVariants}>
								<IconNavLink size={20} color={textColor} />
							</MDiv>
						)}
					</MDiv>
				</Link>
			)}
		</MDiv>
	);
};
export default AnimatedMenuItem;
