import { useApolloClient } from "@apollo/client";
import { useEffect, useRef } from "react";
import log from "src/helpers/log";
import handleConfirmAssets from "../helpers/handleConfirmAssets";
import handleDownloadAssets from "../helpers/handleDownloadAssets";
import handleUploadAssets from "../helpers/handleUploadAssets";
import useAssetPersist, { getAssetPersist } from "../stores/useAssetPersist";
import syncDocumentDirectoryAssetPersist from "../syncDocumentDirectoryAssetPersist";
import usePingLiveActivity from "./usePingLiveActivity";
import usePreventClose from "./usePreventClose";

const useSyncAssets = () => {
	usePingLiveActivity();
	const isUploading = useRef(false);
	const isDownloading = useRef(false);
	const apolloClient = useApolloClient();

	const uploadAssetsRef = useRef(async () => {
		if (isUploading.current) return;
		isUploading.current = true;
		const { getUploadAssetsReadyForConfirm, getUploadAssetsReadyForUpload } = getAssetPersist();
		try {
			for (let i = 0; i < 50; i++) {
				const uploadAssets = getUploadAssetsReadyForUpload();
				const confirmAssets = getUploadAssetsReadyForConfirm();
				if (uploadAssets.length === 0 && confirmAssets.length === 0) {
					break;
				}
				await Promise.all([
					uploadAssets.length > 0
						? handleUploadAssets(apolloClient, uploadAssets)
						: { success: true },
					confirmAssets.length > 0
						? handleConfirmAssets(apolloClient, confirmAssets)
						: { success: true },
				]);
			}
		} catch (err) {
			log.error({ err }, "Error uploading assets");
		} finally {
			isUploading.current = false;
		}
	});

	const downloadImagesRef = useRef(async () => {
		if (isDownloading.current) return;
		isDownloading.current = true;
		const { getHasDownloadAssets: getHasDownloadImages } = getAssetPersist();
		try {
			while (getHasDownloadImages()) {
				const { downloadAssetsStore } = getAssetPersist();
				const { success } = await handleDownloadAssets(Object.values(downloadAssetsStore));
				if (!success) return;
			}
		} catch (err) {
			log.error({ err }, "Error downloading assets");
		} finally {
			isDownloading.current = false;
		}
	});

	const intervals = useRef<NodeJS.Timeout[]>([]);
	useEffect(() => {
		let downloadAssetsUnsubscribe: undefined | (() => void) = undefined;
		let uploadAssetsUnsubscribe: undefined | (() => void) = undefined;
		(async () => {
			try {
				// Clearing uploaded images on every app start
				// Hopefully they weren't being used anymore
				await getAssetPersist().clearUploadedAssets();
			} finally {
				downloadImagesRef.current();
				downloadAssetsUnsubscribe = useAssetPersist.subscribe(
					(state) => state.downloadAssetsStore,
					() => {
						downloadImagesRef.current();
					},
				);

				uploadAssetsRef.current();
				uploadAssetsUnsubscribe = useAssetPersist.subscribe(
					(state) => state.uploadAssetsStore,
					() => {
						uploadAssetsRef.current();
					},
				);

				intervals.current.push(setInterval(uploadAssetsRef.current, 3_000));
				intervals.current.push(setInterval(downloadImagesRef.current, 10_000));
			}
		})();

		return () => {
			downloadAssetsUnsubscribe?.();
			uploadAssetsUnsubscribe?.();
			for (const interval of intervals.current) {
				clearInterval(interval);
			}
			intervals.current = [];
		};
	}, []);

	useEffect(() => {
		syncDocumentDirectoryAssetPersist();
	}, []);
	usePreventClose();
};
export default useSyncAssets;
