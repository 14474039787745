import toast from "src/swsh-native/toast";
import { Platform } from "react-native";
import log from "src/helpers/log";
import { DownloadAssetInfo } from "../../types";
import downloadAsset from "./helpers/downloadAsset";

const shouldStagger = Platform.OS === "web";
const CHUNK_SIZE = shouldStagger ? 1 : 3;
const CHUNK_DELAY = shouldStagger ? 200 : 0;
const handleDownloadAssets = async (
	assets: DownloadAssetInfo[],
): Promise<{
	success: boolean;
}> => {
	if (assets.length === 0)
		return {
			success: true,
		};
	let numFailures = 0;
	try {
		const chunkedImages: DownloadAssetInfo[][] = [];

		for (let c = 0; c < assets.length; c += CHUNK_SIZE) {
			chunkedImages.push(assets.slice(c, c + CHUNK_SIZE));
		}

		for (const chunk of chunkedImages) {
			await Promise.allSettled(
				chunk.map(async (asset) => {
					const { success } = await downloadAsset(asset);
					if (!success) {
						numFailures++;
					}
				}),
			);

			if (CHUNK_DELAY > 0) {
				await new Promise((resolve) => setTimeout(resolve, CHUNK_DELAY));
			}
		}
		return {
			success: numFailures === 0,
		};
	} catch (err) {
		log.error({ err }, "Error downloading assets");
		toast({
			preset: "error",
			title: "Error downloading content",
			message: "Please try again later",
		});
		return {
			success: false,
		};
	} finally {
		if (numFailures > 0) {
			toast({
				preset: "error",
				title: `${numFailures} item${numFailures > 1 ? "s" : ""} failed to download`,
				message: "Please try again later",
			});
		}
	}
};
export default handleDownloadAssets;
