import { useEffect } from "react";
import useSyncAssets from "src/api/asset-persist/hooks/useSyncAssets";
import syncDocumentDirectoryAssetPersist from "src/api/asset-persist/syncDocumentDirectoryAssetPersist";
import UploadDownloadToast from "./components/UploadDownloadToast";

const SyncImages = () => {
	useSyncAssets();
	useEffect(() => {
		syncDocumentDirectoryAssetPersist();
	}, []);

	return <UploadDownloadToast />;
};
export default SyncImages;
