import { track } from "@amplitude/analytics-react-native";
import { ApolloClient } from "@apollo/client";
import { amp } from "../../api/amplitude";
import { getAssetPersist } from "../../api/asset-persist/stores/useAssetPersist";
import { alert } from "../../swsh-native";
import handleClearCache from "./helpers/handleClearCache";

const clearCache = async (
	apolloClient: ApolloClient<any>,
	{ skipConfirmation = false }: { skipConfirmation?: boolean } = {},
) => {
	track(amp.cache.clear.prompt);
	if (getAssetPersist().getHasUploadAssets() && !skipConfirmation) {
		alert({
			title: "Are you sure you want to clear the cache?",
			message: "This will clear any uploading images.",
			buttons: [
				{
					text: "Cancel",
					onPress: () => {
						track(amp.cache.clear.cancel);
					},
					style: "cancel",
				},
				{
					text: "Clear",
					onPress: async () => {
						track(amp.cache.clear.confirm);
						await handleClearCache(apolloClient);
					},
					style: "destructive",
				},
			],
		});
	} else {
		track(amp.cache.clear.noPromptClear);
		await handleClearCache(apolloClient);
	}
};
export default clearCache;
