import { useCallback, useRef } from "react";
import { uploadDownloadToastFocusedVar } from "src/api/graphql/variables";
import UploadDownloadToastBalancer from "./components/UploadDownloadToastBalancer/UploadDownloadToastBalancer";
import { createUseAssetCountInfo } from "./hooks/useAssetCountInfo";

const useUploadingCountInfo = createUseAssetCountInfo("upload");
const useDownloadingCountInfo = createUseAssetCountInfo("download");

const UploadDownloadToast = () => {
	const uploadInfo = useUploadingCountInfo();
	const downloadInfo = useDownloadingCountInfo();

	const isOpenRef = useRef(false);
	const isFocusedRef = useRef(true);
	const handleOpenChange = useCallback((isOpen: boolean) => {
		isOpenRef.current = isOpen;
		const newIsFocused = isOpenRef.current && isFocusedRef.current;
		uploadDownloadToastFocusedVar(newIsFocused);
	}, []);
	const handleFocusChange = useCallback((isFocused: boolean) => {
		isFocusedRef.current = isFocused;
		const newIsFocused = isOpenRef.current && isFocusedRef.current;
		uploadDownloadToastFocusedVar(newIsFocused);
	}, []);

	return (
		<UploadDownloadToastBalancer
			downloadInfo={downloadInfo}
			uploadInfo={uploadInfo}
			onOpenChange={handleOpenChange}
			onFocusChange={handleFocusChange}
		/>
	);
};
export default UploadDownloadToast;
