import { setContext } from "@apollo/client/link/context";

/**
 * Web auth middleware is automatically handled by cookies
 */
const authMiddleware = setContext(async (args0, args1) => {
	const { headers } = args1;
	return {
		headers: {
			...headers,
			authorization: `Bearer SESSION`,
		},
	};
});

export default authMiddleware;
