import { FieldPolicy } from "@apollo/client";
import { User, UsersCursor } from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import mergeCursor from "../helpers/mergeCursor";

const cacheMergeUsers: FieldPolicy<UsersCursor | null> = {
	keyArgs: (args) => {
		const input = args?.input ?? {};
		let key = "";
		if (input?.allowedRequested) {
			key += `aR:${input.allowedRequested}#`;
		}
		if (input?.variant) {
			key += `s:${input.variant}#`;
		}
		// Search should be last since user can _technically_ input any above keys as their search
		if (input?.search && input.search.length > 0) {
			key += `s:${input.search}#`;
		}
		return key;
	},
	merge: (existing, incoming, { readField }) => {
		const seenIds = new Set<string>();
		const retObj = {
			cursor: mergeCursor(existing, incoming, { readField }),
			payload: [] as User[],
		};
		for (const item of [...(existing?.payload ?? []), ...(incoming?.payload ?? [])]) {
			const userId = readField<string>("userId", item);
			if (!userId) {
				log.error("userId is null");
				continue;
			}
			if (seenIds.has(userId)) continue;
			seenIds.add(userId);
			retObj.payload.push(item);
		}
		return retObj;
	},
};
export default cacheMergeUsers;
