type NonNullType = string | number | boolean;
const encodeURIArgs = (
	args: Record<string, NonNullType | undefined | null>,
	{
		prefix = "?",
	}: {
		prefix?: "?" | "&";
	} = {},
): string => {
	const query = Object.entries(args)
		.filter(([, value]) => value !== undefined && value !== null)
		.map(([key, value]) => `${key}=${encodeURIComponent(value as NonNullType)}`)
		.join("&");
	return query ? `${prefix}${query}` : "";
};
export default encodeURIArgs;
