import { track } from "@amplitude/analytics-react-native";
import { ApolloClient, ApolloError, makeVar } from "@apollo/client";
import toast from "src/swsh-native/toast";
import { amp } from "src/api/amplitude";
import { UploadPhotosInput } from "src/api/graphql/__generated__/graphql";
import native from "src/constants/native";
import SystemInfo from "src/constants/SystemInfo";
import clearCache from "src/helpers/clearCache";
import log from "src/helpers/log";
import { alert } from "src/swsh-native";
import { UploadAssetInfo } from "../../types";
import GraphQLUploadPhotosDocument from "./documents/GraphQLUploadPhotosDocument";
import processUploadPhotosBatch from "./helpers/processUploadPhotosBatch";
import wipeChunk from "./helpers/wipeChunk";

const shouldPromptClearCacheVar = makeVar(true);
const numBatchFailCountVar = makeVar(0);

const getUploadStrategy = (): {
	shouldBackgroundUpload: boolean;
} & (
	| {
			baseChunkSize: number;
			chunkSizeIncrement: number;
			maxChunkSize: number;
			variationSize: number;
			chunkSize?: never;
	  }
	| {
			baseChunkSize?: never;
			chunkSizeIncrement?: never;
			maxChunkSize?: never;
			variationSize?: never;
			chunkSize: number;
	  }
) => {
	const shouldBackgroundUpload = native;
	if (shouldBackgroundUpload) {
		return {
			shouldBackgroundUpload: true,
			chunkSize: 500,
		};
	}
	if (SystemInfo.isMobileWeb) {
		return {
			shouldBackgroundUpload: false,
			baseChunkSize: 1,
			chunkSizeIncrement: 6,
			maxChunkSize: 20,
			variationSize: 4,
		};
	} else {
		return {
			shouldBackgroundUpload: false,
			baseChunkSize: 1,
			chunkSizeIncrement: 10,
			maxChunkSize: 30,
			variationSize: 6,
		};
	}
};

const handleUploadAssets = async (
	apolloClient: ApolloClient<object>,
	images: UploadAssetInfo[],
): Promise<{
	success: boolean;
}> => {
	if (images.length === 0) {
		return {
			success: true,
		};
	}
	let numFailed = 0;
	const {
		shouldBackgroundUpload,
		chunkSize: overrideChunkSize,
		baseChunkSize,
		chunkSizeIncrement,
		maxChunkSize,
		variationSize,
	} = getUploadStrategy();
	try {
		const chunkedImages: Record<string, UploadAssetInfo[][]> = {};
		let numChunks = 0;
		let chunkSize = overrideChunkSize ?? baseChunkSize;
		for (const image of images) {
			const id = image.gatheringId ?? "_";
			if (!chunkedImages[id]) {
				chunkedImages[id] = [];
			}
			const section = chunkedImages[id]!;
			if (section.length === 0 || (section[section.length - 1]?.length ?? 0) >= chunkSize) {
				section.push([]);
				numChunks++;
			}

			chunkedImages[id]![section.length - 1]!.push(image);
			chunkSize =
				overrideChunkSize ??
				Math.min(
					baseChunkSize + (numChunks - 1) * chunkSizeIncrement,
					maxChunkSize + Math.floor(variationSize * Math.random()),
				);
		}

		for (const section of Object.values(chunkedImages)) {
			const numPhotosInSection = section.reduce((acc, curr) => acc + curr.length, 0);

			for (const chunk of section) {
				const imageLookup: Record<string, UploadAssetInfo> = {};
				const gatheringId = chunk[0]?.gatheringId;

				for (const image of chunk) {
					if (image.gatheringId !== gatheringId) {
						throw new Error("Images in a chunk must all have the same gatheringId");
					}
					imageLookup[image.localPhotoId] = image;
				}
				const { data } = await apolloClient
					.mutate({
						mutation: GraphQLUploadPhotosDocument,
						variables: {
							input: {
								photos: chunk.map((image) => ({
									localPhotoId: image.localPhotoId,
									isThumbnail: !!image.isThumbnail,
									variant: image.uriInfo.variant,
									localSelectionId: image.localSelectionId,
									localSelectionIndex: image.localSelectionIndex,
								})) satisfies UploadPhotosInput["photos"],
								gatheringId: gatheringId ?? undefined,
								numTotalPhotos: numPhotosInSection,
								isBackgroundUpload: shouldBackgroundUpload,
							},
						},
					})
					.catch(async (err) => {
						if (err instanceof ApolloError) {
							if (
								err.graphQLErrors.some(
									(err) => err.extensions?.wipeUploadBatch === true,
								)
							) {
								log.error({ err }, "Wiping upload batch due to error");
								await wipeChunk(chunk);
								numFailed += chunk.length;
							}
							return {
								data: null,
							};
						}
						return { data: null };
					});
				await processUploadPhotosBatch(data, {
					apolloClient,
					imageLookup,
				});
			}
		}
		if (numFailed > 0) {
			const prevNumBatchFailCount = numBatchFailCountVar();
			numBatchFailCountVar(prevNumBatchFailCount + 1);
			toast({
				preset: "error",
				title: "Network Error",
				message: `Issues uploading ${numFailed} images`,
			});
			if (prevNumBatchFailCount >= 1 && shouldPromptClearCacheVar()) {
				shouldPromptClearCacheVar(false);
				alert({
					title: "We're having some issues uploading your photos",
					message:
						"Would you like to clear your cache to give up? You can clear the cache anytime in settings.",
					buttons: [
						{
							text: "Dismiss",
							style: "cancel",
						},
						{
							text: "Clear",
							onPress: async () => {
								track(amp.upload.clearCache);
								await clearCache(apolloClient, {
									skipConfirmation: true,
								});
								shouldPromptClearCacheVar(true);
							},
							style: "destructive",
						},
					],
				});
			}
		}
		return {
			success: numFailed === 0,
		};
	} catch (err) {
		log.error({ err }, "Error uploading assets");
		return {
			success: false,
		};
	}
};
export default handleUploadAssets;
