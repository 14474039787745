import { FieldPolicy } from "@apollo/client";
import { ReactionUser, ReactionUsersCursor, User } from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import mergeCursor from "../helpers/mergeCursor";

const cacheMergeReactionUsers: FieldPolicy<ReactionUsersCursor | null> = {
	keyArgs: ["input", ["key"]],
	merge: (existing, incoming, { readField }) => {
		const seenIds = new Set<string>();
		const retObj = {
			cursor: mergeCursor(existing, incoming, { readField }),
			payload: [] as ReactionUser[],
		};
		for (const item of [...(existing?.payload ?? []), ...(incoming?.payload ?? [])]) {
			const user = readField<User>("user", item);
			const userId = readField<string>("userId", user);
			if (!userId) {
				log.error("userId is null");
				continue;
			}
			if (seenIds.has(userId)) continue;
			seenIds.add(userId);
			retObj.payload.push(item);
		}
		return retObj;
	},
};
export default cacheMergeReactionUsers;
