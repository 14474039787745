import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import mmkv from "../index";

/**
 * Reacts to variable changes and persists them to MMKV. Also updates based on a new client var to the correct value
 */
const usePersistVariable = <T>(
	persistVariable: Record<string, ReactiveVar<T | null>>,
	{ prefix }: { prefix?: string } = {
		prefix: "persist-var",
	},
) => {
	const objKeys = Object.keys(persistVariable);
	if (objKeys.length !== 1) {
		throw new Error("usePersistVariable only supports one variable at a time");
	}
	const key = objKeys[0]!;
	const variable = persistVariable[key as any] as ReactiveVar<T | null>;
	const storageKey = `${prefix}-${key}`;

	const value = useReactiveVar(variable);
	const curStored = useRef<any>(null);

	const writeToStorage = useCallback(
		(value: T | null) => {
			const toStore = JSON.stringify(value);
			if (curStored.current === toStore) return;
			mmkv.set(storageKey, toStore);
			curStored.current = toStore;
		},
		[storageKey],
	);
	const readFromStorage = useCallback(() => {
		const stored = mmkv.getString(storageKey);
		if (!stored) return null;
		const parsed = JSON.parse(stored);
		return parsed;
	}, [storageKey]);

	const didInitialRead = useRef(false);
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!didInitialRead.current) return;
		writeToStorage(value);
	}, [value]);

	useEffect(() => {
		didInitialRead.current = true;
		variable(readFromStorage());
	}, [readFromStorage, variable]);
};
export default usePersistVariable;
