import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { radiusSmall } from "src/styles/radius";
import { paddingMedium } from "src/styles/spacing";
import FocusWrapper from "./components/FocusWrapper";
import LabeledTextInputCustomChildren from "./components/LabeledTextInputCustomChildren";
import LabeledTextInputWithProps from "./components/LabeledTextInputWithProps";
import { LabeledTextInputHandler, LabeledTextInputProps } from "./types";

const LabeledTextInput = (
	{
		sizeVariant = "Medium",
		validator,
		characterLimit,
		testID,
		style,
		onFocus,
		onBlur,
		...props
	}: LabeledTextInputProps,
	ref: ForwardedRef<LabeledTextInputHandler>,
) => {
	const handleValidate = useCallback(
		(text: string) => {
			if (characterLimit !== undefined && text.length > characterLimit) {
				return false;
			}
			if (validator && !validator(text)) {
				return false;
			}
			return true;
		},
		[characterLimit, validator],
	);
	const [isFocused, setIsFocused] = useState(false);
	const handleSetFocus: typeof onFocus = (e) => {
		onFocus?.(e);
		setIsFocused(true);
	};
	const handleClearFocus: typeof onBlur = (e) => {
		onBlur?.(e);
		setIsFocused(false);
	};
	const { theme } = useStyle();

	return (
		<FocusWrapper
			isFocused={isFocused}
			testID={testID}
			style={[
				{
					paddingHorizontal: paddingMedium,
					paddingVertical: paddingMedium,
					borderRadius: radiusSmall,
					backgroundColor: color.InputBackground[theme],
				},
				style,
			]}
		>
			{!!props.children ? (
				<LabeledTextInputCustomChildren
					{...props}
					validator={handleValidate}
					sizeVariant={sizeVariant}
					characterLimit={characterLimit}
					testID={testID}
					onFocus={handleSetFocus}
					onBlur={handleClearFocus}
					parentRef={ref}
				/>
			) : (
				<LabeledTextInputWithProps
					{...props}
					sizeVariant={sizeVariant}
					validator={handleValidate}
					characterLimit={characterLimit}
					testID={testID}
					onFocus={handleSetFocus}
					onBlur={handleClearFocus}
					parentRef={ref}
				/>
			)}
		</FocusWrapper>
	);
};
export default forwardRef(LabeledTextInput);
