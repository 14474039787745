import { Platform } from "react-native";
import parseUserAgent from "src/helpers/parseUserAgent/parseUserAgent";
import isWeb from "../isWeb";
import native from "../native";
import userAgent from "../userAgent";
import { SystemInfoObj } from "./types";

const { OS, inAppBrowser } = parseUserAgent(userAgent);
export const SystemInfo: SystemInfoObj = {
	isNative: native,
	isWeb,
	OS: OS ?? Platform.OS,
	inAppBrowser: inAppBrowser ?? null,
	isMobileWeb: isWeb && (OS === "ios" || OS === "android" || OS === "windows phone"),
};
