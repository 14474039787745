import log from "src/helpers/log";
import { WriteAssetData } from "./types";

const writeAssetData: WriteAssetData = async (image, writeUrl) => {
	if (!image.fileInfo) {
		throw new Error("Missing file info");
	}
	const promises: Promise<Response>[] = [];
	if (writeUrl.url && image.fileInfo.image) {
		promises.push(
			fetch(writeUrl.url, {
				method: "PUT",
				body: image.fileInfo.image.file,
				headers: {
					"Content-Type": image.fileInfo.image.file.type,
				},
			}),
		);
	}
	if (writeUrl.videoUrl && image.fileInfo.video) {
		promises.push(
			fetch(writeUrl.videoUrl, {
				method: "PUT",
				body: image.fileInfo.video.file,
				headers: {
					"Content-Type": image.fileInfo.video.file.type,
				},
			}),
		);
	}
	const results = await Promise.all(promises);
	for (const res of results) {
		if (!res.ok) {
			log.error(
				{
					status: res.status,
					statusText: res.statusText,
				},
				"Error uploading photo data",
			);
			throw new Error("Error uploading photo data");
		}
	}
};
export default writeAssetData;
