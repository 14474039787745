import { gql } from "@apollo/client";

/**
 * WARNING: This must match './clientDefs.ts'. I don't have a better solution for this right now.
 */
export const clientSchema = gql`
	extend type Query {
		_receivedAt: Int
	}
	extend type User {
		filler: String
		_receivedAt: Int
	}
	extend type Update {
		receivedAt: Int
	}
`;
