import { makeVar } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Platform } from "react-native";
import forceLogout from "src/api/auth/forceLogout";
import native from "src/constants/native";
import log from "src/helpers/log";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import { Timestamp_MS } from "src/shared/types/general";
import useSplashScreenStore from "src/stores/useSplashScreenStore";
import { alert } from "src/swsh-native";
import toast from "src/swsh-native/toast";
import { appStateVar } from "../variables";
import { processCookieInOperation } from "./cookieMiddleware";

const DEBOUNCE_TIME = 6_000;

const errorCache = makeVar<Record<string, { lastDisplayedAt: Timestamp_MS }>>({});

const debouncedToastError = ({
	message,
	subMessage,
	extraData,
	debounceTime = DEBOUNCE_TIME,
}: {
	message: string;
	subMessage?: string;
	extraData?: string;
	debounceTime?: Timestamp_MS;
}) => {
	const messageKey = `${message}\n${subMessage}\n${extraData}`;
	const cur_MS = getCurTime_MS();
	const existingError = errorCache()[messageKey];
	if (!existingError || cur_MS - existingError.lastDisplayedAt > debounceTime) {
		errorCache({
			...errorCache(),
			[messageKey]: { lastDisplayedAt: cur_MS },
		});
		toast({
			preset: "error",
			title: message,
			message: subMessage,
		});
	}
};

let didSchemaMismatchError = false;

const errorMiddleware = onError(({ graphQLErrors, networkError, operation }) => {
	if (Platform.OS !== "web") {
		processCookieInOperation(operation);
	}

	let didSessionExpire = false;
	if (graphQLErrors) {
		for (const { message, path, extensions = {} } of graphQLErrors) {
			if (message.includes("You are not authorized to make this call")) {
				// Handling in networkError
				continue;
			}
			if (extensions.clientSafe === true || message === "Something went wrong!") {
				if (extensions.fault === "Client") {
					log.warn(
						{
							errorMessage: message,
							path,
							extensions,
						},
						"[Displayed GraphQL Error]",
					);
				} else {
					log.error(
						{
							errorMessage: message,
							path,
							extensions,
						},
						"[Displayed Client Error]",
					);
				}
				if (extensions.format === "Alert") {
					const title: string =
						typeof extensions.title === "string" ? extensions.title : "Error";
					const confirmationText: string =
						typeof extensions.confirmationText === "string"
							? extensions.confirmationText
							: "OK";
					alert({
						title,
						message,
						buttons: [{ text: confirmationText, style: "default" }],
					});
				} else {
					const subMessage: string | undefined =
						typeof extensions.subMessage === "string"
							? extensions.subMessage
							: undefined;

					// Want to send a new error if mutation
					const isMutation = operation.query.definitions.some(
						(def) => def.kind === "OperationDefinition" && def.operation === "mutation",
					);
					if (isMutation) {
						toast({
							preset: "error",
							title: message,
							message: subMessage,
						});
					} else {
						debouncedToastError({
							message,
							subMessage,
						});
					}
				}
			}
			// Schema mismatch
			else if (
				(message.startsWith("Cannot query field") ||
					message.startsWith("Unknown argument") ||
					message.startsWith("Missing field") ||
					message.startsWith("Unknown type")) &&
				!didSchemaMismatchError
			) {
				didSchemaMismatchError = true;
				log.error({ message }, "Schema mismatch error");
				alert({
					title: "Update required",
					message:
						"You're using an old version of swsh! Some features may not work as expected. Please update to the latest version.",
					buttons: [
						{
							text: "OK",
						},
					],
				});
			} else if (extensions.action === "LOGOUT") {
				didSessionExpire = true;
				const cur_MS = getCurTime_MS();
				const isLoggingOut_MS = useSplashScreenStore.getState().logoutInitializedAt;
				if (isLoggingOut_MS === null || cur_MS - isLoggingOut_MS > 10_000) {
					useSplashScreenStore.setState({
						logoutInitializedAt: cur_MS,
					});
					log.warn("Expired session, logging out...");
					toast({
						preset: "error",
						title: "Expired session",
					});
					if (native) {
						// logoutInitializedAt reset in forceLogout
						forceLogout();
					} else {
						// Giving warning time to show
						setTimeout(() => {
							forceLogout();
						}, 1000);
					}
				}
			} else {
				log.error(
					{
						errorMessage: message,
						path,
						extensions,
					},
					"[GraphQL Error]",
				);
				if (appStateVar() !== "background") {
					debouncedToastError({ message: "Server Error", debounceTime: 10_000 });
				}
			}
		}
	}
	if (networkError && !didSessionExpire) {
		if (
			typeof networkError.message === "string" &&
			networkError.message.includes("status code 401")
		) {
			// Any logout behavior is handled based on extensions.errorCause
			log.error("Unexpected 401 auth error");
		} else {
			if (appStateVar() !== "background") {
				log.warn(
					{
						networkError,
					},
					"Network Error",
				);
				debouncedToastError({ message: "Network Error", debounceTime: 10_000 });
			}
		}
	}
});

export default errorMiddleware;
