import Constants from "expo-constants";
import { ColorValue, Platform } from "react-native";
import { Directions } from "react-native-gesture-handler";
import DeviceType from "src/api/graphql/__generated__/enums/DeviceType";
import { Timestamp_MS } from "src/shared/types/general";

export const getDeviceType = () => {
	if (Constants.appOwnership === "expo") {
		throw new Error("Expo Go is no longer spported. Please use the standalone app.");
	}
	if (Platform.OS === "ios") {
		return DeviceType.Ios;
	}
	if (Platform.OS === "android") {
		return DeviceType.Android;
	}
	if (Platform.OS === "web") {
		return DeviceType.Web;
	}
	throw new Error("Failed to get device type");
};

export const parseExifDate = (s: any) => {
	try {
		var b = s.split(/\D/);
		return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
	} catch (error) {
		return null;
	}
};

/**
 * Tries to run async function but gives up after timeout and throws an error. Does not necessarily terminate the async function nicely
 */
export const timedAsync = async <T>(asyncFunc: () => Promise<T>, timeout: Timestamp_MS) => {
	const res = await Promise.race([
		asyncFunc(),
		new Promise((resolve, reject) =>
			setTimeout(() => {
				reject("Timeout");
			}, timeout),
		),
	]);
	return res as T;
};

export const shuffle = <TItem>(array: TItem[]): TItem[] => {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex != 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex]!, array[currentIndex]!];
	}

	return array;
};

export const objectMap = <T extends Record<string, any>>(
	object: T | undefined,
	mapFn: (key: keyof T, value: T[keyof T]) => any,
) => {
	if (!object) return undefined;
	return Object.keys(object).reduce(
		(result, key) => {
			result[key] = mapFn(key, object[key]);
			return result;
		},
		{} as Record<string, any>,
	);
};

export const weightedRandom = <T extends Record<any, number>>(weights: T) => {
	const totalWeight = Object.values(weights).reduce((a, b) => a + b, 0);
	const random = Math.random() * totalWeight;
	let weightSum = 0;
	for (const [key, weight] of Object.entries(weights)) {
		weightSum += weight;
		if (weightSum >= random) {
			return key;
		}
	}
	return Object.keys(weights)[0];
};

export const dissectColor = (color: ColorValue) => {
	if (typeof color === "string") {
		if (color.includes("#")) {
			const hex = color.replace("#", "");
			return [
				parseInt(hex.substring(0, 2), 16),
				parseInt(hex.substring(2, 4), 16),
				parseInt(hex.substring(4, 6), 16),
				1,
			];
		} else if (color.includes("rgb") || color.includes("hsl")) {
			const rgb = color.replace("rgb(", "").replace("hsl(", "").replace(")", "").split(",");
			if (!rgb[0] || !rgb[1] || !rgb[2]) {
				throw new Error("Invalid color: " + color);
			}
			return [parseInt(rgb[0]), parseInt(rgb[1]), parseInt(rgb[2]), 1];
		} else if (color.includes("rgba") || color.includes("hsla")) {
			const rgb = color.replace("rgba(", "").replace("hsla(", "").replace(")", "").split(",");
			if (!rgb[0] || !rgb[1] || !rgb[2] || !rgb[3]) {
				throw new Error("Invalid color: " + color);
			}
			return [parseInt(rgb[0]), parseInt(rgb[1]), parseInt(rgb[2]), parseFloat(rgb[3])];
		} else if (color.includes("transparent")) {
			return [0, 0, 0, 0];
		}
	}
	throw new Error("Weird color passed in to dissection " + color.toString());
};
export const calculateColorInterpolation = (
	percent: number,
	minColor: ColorValue,
	maxColor: ColorValue,
) => {
	const minColorDissected = dissectColor(minColor);
	const maxColorDissected = dissectColor(maxColor);
	if (
		!minColorDissected[0] ||
		!minColorDissected[1] ||
		!minColorDissected[2] ||
		!minColorDissected[3] ||
		!maxColorDissected[0] ||
		!maxColorDissected[1] ||
		!maxColorDissected[2] ||
		!maxColorDissected[3]
	) {
		throw new Error("Invalid color");
	}
	const r = Math.round(
		minColorDissected[0] + (maxColorDissected[0] - minColorDissected[0]) * percent,
	);
	const g = Math.round(
		minColorDissected[1] + (maxColorDissected[1] - minColorDissected[1]) * percent,
	);
	const b = Math.round(
		minColorDissected[2] + (maxColorDissected[2] - minColorDissected[2]) * percent,
	);
	const a = Math.round(
		minColorDissected[3] + (maxColorDissected[3] - minColorDissected[3]) * percent,
	);
	return `rgba(${r},${g},${b},${a})`;
};

export const numCmp = (a: number, b: number) => {
	if (a < b) return -1;
	if (a > b) return 1;
	return 0;
};

export const clamp = (num: number, min: number, max: number) => {
	return Math.min(Math.max(num, min), max);
};

export const clampWorklet = (num: number, min: number, max: number) => {
	"worklet";
	return Math.min(Math.max(num, min), max);
};
export const sign = (num: number) => {
	if (num > 0) return 1;
	if (num < 0) return -1;
	return 0;
};
export const signWorklet = (num: number) => {
	"worklet";
	if (num > 0) return 1;
	if (num < 0) return -1;
	return 0;
};

export const largestMagnitude = (a: number, b: number) => {
	return Math.abs(a) > Math.abs(b) ? a : b;
};

export const largestMagnitudeWorklet = (a: number, b: number) => {
	"worklet";
	return Math.abs(a) > Math.abs(b) ? a : b;
};

export const getSortByObject = (sortBy: string[]) =>
	sortBy.reduce(
		(a, c, i) => {
			a[c] = i;
			return a;
		},
		{} as Record<string, number>,
	);

export const deDupOnKey = <T extends Record<any, any>>(
	arr: (T | null | undefined)[],
	key: keyof T,
): T[] => {
	if (!arr) return [];
	const map = new Map();
	arr.forEach((item) => {
		if (!item) return;
		map.set(item[key], item);
	});
	return Array.from(map.values());
};

export const getDirection = (
	event: Record<string, number>,
	{
		threshold,
		property,
	}: {
		threshold?: number;
		property?: {
			x: string;
			y: string;
		};
	} = {},
) => {
	var direction;
	threshold = threshold || 50;
	property = property || { x: "velocityX", y: "velocityY" };
	const x = event[property.x];
	const y = event[property.y];
	if (!x || !y) return undefined;
	if (Math.abs(x) > Math.abs(y)) {
		if (x > 0 && Math.abs(y) < threshold) {
			direction = Directions.RIGHT;
		}
		if (x < 0 && Math.abs(y) < threshold) {
			direction = Directions.LEFT;
		}
	} else {
		if (y > 0 && Math.abs(x) < threshold) {
			direction = Directions.DOWN;
		}
		if (y < 0 && Math.abs(x) < threshold) {
			direction = Directions.UP;
		}
	}
	return direction;
};
