import Loading from "src/components/Loading";
import { color } from "src/styles";

const FadeInLoading = ({ testID }: { testID?: string }) => {
	return (
		<div
			data-testid={testID}
			style={{
				display: "flex",
				position: "absolute",
				width: "100vw",
				height: "100vh",
				backgroundColor: color.Background["Dark"],
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Loading />
		</div>
	);
};
export default FadeInLoading;
