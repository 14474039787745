import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

const mergeCursor = <
	TObj extends object = {
		cursor: string | null;
	},
>(
	existing: Readonly<TObj> | null | undefined,
	incoming: Readonly<TObj> | null | undefined,
	{
		// @ts-expect-error
		key = "cursor",
		reverse = false,
		readField,
	}: {
		key?: keyof TObj;
		reverse?: boolean;
		readField: ReadFieldFunction;
	},
) => {
	const existingCursor = existing
		? readField<string | null>(key as string, existing as any)
		: null;
	const incomingCursor = incoming
		? readField<string | null>(key as string, incoming as any)
		: null;
	if (existingCursor && incomingCursor) {
		if (incomingCursor === "{}") return incomingCursor;
		if (reverse) {
			if (incomingCursor < existingCursor) {
				return incomingCursor;
			}
			return existingCursor;
		} else {
			if (incomingCursor > existingCursor) {
				return incomingCursor;
			}
			return existingCursor;
		}
	}
	return incomingCursor ?? existingCursor ?? null;
};
export default mergeCursor;
