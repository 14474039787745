import { ApolloClient } from "@apollo/client";
import { ReactNode } from "react";
import type { CompositionKey, CompositionsLightType } from "skia-motion/src/CompositionsLight";
import type { PropsFromCompositionConfig } from "skia-motion/src/types";
import { RecapFragmentFragment } from "src/api/graphql/__generated__/graphql";
import CustomThemeModalSkeleton from "src/screens/(internal)/CustomThemeModal/CustomThemeModalSkeleton";
import { CustomThemeModalProps } from "src/screens/(internal)/CustomThemeModal/types";
import EditCoverGalleryModalSkeleton from "src/screens/(internal)/EditCoverGalleryModal/EditCoverGalleryModalSkeleton";
import { EditCoverGalleryModalProps } from "src/screens/(internal)/EditCoverGalleryModal/types";
import RecapModalSkeleton from "src/screens/(internal)/RecapModal/RecapModalSkeleton";
import SearchModalSkeleton from "src/screens/(internal)/SearchModal/SearchModalSkeleton";
import ShareableModalSkeleton from "src/screens/(internal)/ShareableModal/ShareableModalSkeleton";
import lazy from "src/swsh-native/lazy";
import { ModalConfig } from "../types";

const ShareableModal = lazy(() => import("src/screens/(internal)/ShareableModal"), {
	loading: ShareableModalSkeleton,
});
const SearchModal = lazy(() => import("src/screens/(internal)/SearchModal"), {
	loading: SearchModalSkeleton,
});
const EditCoverGalleryModal = lazy(() => import("src/screens/(internal)/EditCoverGalleryModal"), {
	loading: EditCoverGalleryModalSkeleton,
});
const CustomThemeModal = lazy(() => import("src/screens/(internal)/CustomThemeModal"), {
	loading: CustomThemeModalSkeleton,
});
const RecapModal = lazy(() => import("src/screens/(internal)/RecapModal"), {
	loading: RecapModalSkeleton,
});

export interface PreviewImageProps {
	url: string;
	width?: number;
	height?: number;
	openGraphTitle?: string;
	openGraphUrl?: string;
}

export type ShareableGetProps<
	TComp extends CompositionKey = CompositionKey,
	TContext extends object | null = null,
> = (args: {
	apolloClient: ApolloClient<object>;
}) => Promise<ShareableGetPropsResponse<TComp, TContext>>;
export type ShareableGetPropsResponse<
	TComp extends CompositionKey = CompositionKey,
	TContext extends object | null = null,
> =
	| {
			success: true;
			props: PropsFromCompositionConfig<CompositionsLightType[TComp]>;
			context: TContext;
	  }
	| {
			success: false;
			ErrorComponent?: () => ReactNode;
	  };

export type ShareableGetDownloadUrl<
	TComp extends CompositionKey = CompositionKey,
	TContext extends object | null = null,
> = (args: {
	apolloClient: ApolloClient<object>;
	props: PropsFromCompositionConfig<CompositionsLightType[TComp]>;
	context: TContext;
}) => Promise<{
	type: "Video";
	url: string;
} | null>;

export interface ShareableProps<
	TComp extends CompositionKey = CompositionKey,
	TContext extends object | null = null,
> {
	composition: TComp;
	initialProps?: Promise<ShareableGetPropsResponse<TComp, TContext>>;
	getProps: ShareableGetProps<TComp, TContext>;
	getDownloadUrl: ShareableGetDownloadUrl<TComp, TContext>;
}

type InternalModal = {
	Shareable: {
		props: {
			shareable: ShareableProps<CompositionKey, any>;
			title?: string;
			subTitle?: string;
		};
		prefetch: () => Promise<void>;
	};
	Search: {};
	EditCoverGallery: {
		props: EditCoverGalleryModalProps;
	};
	CustomThemeModal: {
		props: CustomThemeModalProps;
	};
	Recap: {
		props: {
			recapId: string;
			recap?: RecapFragmentFragment;
		};
	};
};

export default InternalModal;

export const InternalModalConfig = {
	Shareable: {
		component: ShareableModal,
		variant: "Full",
		prefetch: async () => {
			const prefetchSkia = (await import("skia-motion/src/player/helpers/prefetchSkia"))
				.default;
			await prefetchSkia();
		},
	},
	Search: {
		component: SearchModal,
		variant: "Full",
	},
	EditCoverGallery: {
		component: EditCoverGalleryModal,
		variant: "FullBlur",
	},
	CustomThemeModal: {
		component: CustomThemeModal,
		variant: "Short",
	},
	Recap: {
		component: RecapModal,
		variant: "Full",
	},
} satisfies ModalConfig<InternalModal>;
