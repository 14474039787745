export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const COOKIE = "COOKIE";
export const HAS_EAS_UPDATE = "HAS_EAS_UPDATE";

export const DEVICE_GO = "GO";
export const DEVICE_IOS = "IOS";
export const DEVICE_ANDROID = "ANDROID";

export const MIN_TO_MS = 60 * 1000;
export const HOUR_TO_MS = 60 * MIN_TO_MS;
export const DAY_TO_MS = 24 * HOUR_TO_MS;
export const YEAR_TO_MS = 365 * DAY_TO_MS;

export const DAY_TO_S = 24 * 60 * 60;

export const MIN_TO_S = 60;
export const HOUR_TO_S = 60 * 60;

export const HOUR_TO_MIN = 60;
export const DAY_TO_MIN = 24 * HOUR_TO_MIN;

export const MONTH_ABBREV = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];
export const DAY_OF_THE_WEEK_ABBREV = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const DAY_OF_THE_WEEK = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];
