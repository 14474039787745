import toast from "src/swsh-native/toast";
import { useCallback, useRef } from "react";
import { View } from "react-native";
import Header from "src/components/Header";
import LabeledTextInput from "src/components/LabeledTextInput";
import native from "src/constants/native";
import useRouter from "src/hooks/useRouter";
import { useInsideModal } from "src/modals";
import { paddingSpacer, paddingXXL } from "src/styles/spacing";
import parseRefCodeLink from "../_/helpers/parseRefCodeLink";

const CommunityJoinModal = () => {
	const router = useRouter();
	const accessCodeRef = useRef("");
	const { closeSelf } = useInsideModal();

	const handleComplete = useCallback(async () => {
		const refCode = parseRefCodeLink({
			link: accessCodeRef.current,
			split: "group",
		});

		if (!refCode || refCode.length === 0) {
			toast({
				preset: "error",
				title: "Invalid Access Code",
			});
			return;
		}

		router.reset();
		router.push(`/group/${refCode}`);
		if (!native) {
			closeSelf();
		}
	}, [closeSelf, router]);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<Header
				onExit={closeSelf}
				exitVariant="Cancel"
				onComplete={handleComplete}
				completeVariant="Done"
				addSafeArea
			>
				Join group
			</Header>
			<LabeledTextInput
				style={{
					marginTop: paddingSpacer,
					marginHorizontal: paddingXXL,
				}}
				label="Paste link here"
				placeholder="joinswsh.com/group/example"
				onChangeText={(text) => {
					accessCodeRef.current = text;
				}}
				autoFocus
				autoCapitalize="none"
				onSubmitEditing={handleComplete}
			/>
		</View>
	);
};
export default CommunityJoinModal;
