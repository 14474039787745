import { FieldPolicy, Reference, StoreObject } from "@apollo/client";
import { Gathering, GatheringsCursor } from "src/api/graphql/__generated__/graphql";
import mergeCursor from "../helpers/mergeCursor";
import sortKeySortFn from "../helpers/sortKeySortFn";

const cacheCommunityGatherings: FieldPolicy<GatheringsCursor | null> = {
	keyArgs: false,
	merge: (existing, incoming, { readField, mergeObjects }) => {
		const payloadLookup: Record<string, StoreObject | Reference> = {};
		const retObj = {
			cursor: mergeCursor(existing, incoming, {
				readField,
			}),
			payload: [] as Gathering[],
		} satisfies GatheringsCursor;
		for (const item of ([] as Gathering[])
			.concat(incoming?.payload ?? [])
			.concat(existing?.payload ?? [])) {
			const gatheringId = readField<string>("gatheringId", item);
			if (!gatheringId) {
				// Likely means it was evicted from the cache
				continue;
			}
			const cur = payloadLookup[gatheringId];
			if (cur) {
				payloadLookup[gatheringId] = mergeObjects(cur, item);
			}
			payloadLookup[gatheringId] = item;
		}

		retObj.payload = Object.values(payloadLookup) as Gathering[];
		retObj.payload.sort(
			sortKeySortFn(readField, {
				key: "sortKeyCommunityGatherings",
			}),
		);
		return retObj;
	},
};
export default cacheCommunityGatherings;
