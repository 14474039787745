import { useEffect } from "react";
import { getAssetPersist } from "../../stores/useAssetPersist";

const usePreventClose = () => {
	useEffect(() => {
		window.onbeforeunload = () => {
			const uploadAssets = Object.values(getAssetPersist().uploadAssetsStore);
			if (uploadAssets.some((image) => image.uploadState === "Pending")) {
				return "You have content that is still uploading. Are you sure you want to leave?";
			}
			return null;
		};
		return () => {
			window.onbeforeunload = null;
		};
	}, []);
};
export default usePreventClose;
