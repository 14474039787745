import { useCallback, useEffect, useState } from "react";
import { usePathname } from "solito/navigation";
import AnimatedHamburger from "src/components/AnimatedHamburger";
import AnimatedMenu from "src/components/AnimatedMenu/AnimatedMenu.web";
import { IconContainerSize } from "src/components/AnimatedMenu/constants";
import {
	FUNCTIONAL_HAMBURGER_PADDING,
	FUNCTION_HAMBURGER_SIZE,
} from "src/components/FunctionalHamburger/constants";
import Loading from "src/components/Loading";
import { NAV_BAR_HEIGHT } from "src/components/NavBar";
import { NAV_BAR_LOGO_SIZE } from "src/components/NavBar/constants";
import OpacityView from "src/components/OpacityView";
import useKeyboardListener from "src/hooks/useKeyboardListener";
import useStyle from "src/hooks/useStyle";
import IconLogo from "src/icons/IconLogo";
import { useInsideModal } from "src/modals";
import GlobalModal from "src/modals/configs/GlobalModal";
import { color } from "src/styles";
import { paddingLarge } from "src/styles/spacing";
import { Link, MDiv } from "src/swsh-native";

const HamburgerNavModal = () => {
	const { props, closeSelf } = useInsideModal<GlobalModal["HamburgerNav"]>();
	const { onClose, routes } = props;
	const [menuIsOpen, setMenuIsOpen] = useState(true);
	const { theme } = useStyle();

	const handleClose = useCallback(() => {
		setMenuIsOpen(false);
		onClose();
		closeSelf();
	}, [onClose, closeSelf]);

	const [isLoading, setIsLoading] = useState(false);
	const startLoading = useCallback(
		(href: string) => {
			const curHref = window.location.pathname;
			if (curHref === href.split("?")[0]) {
				handleClose();
			} else {
				setIsLoading(true);
			}
		},
		[handleClose],
	);

	const pathname = usePathname();
	useEffect(() => {
		pathname;
		return () => {
			handleClose();
		};
	}, [handleClose, pathname]);

	useKeyboardListener({
		Escape: () => {
			handleClose();
		},
	});

	return (
		<MDiv
			style={{
				height: "100%",
				width: "100%",
				backgroundColor: color.Background[theme],
			}} // Override standard modal color (OK with hacky solution here since this is an exceptional use-case of modals)
		>
			<MDiv
				style={{
					height: NAV_BAR_HEIGHT,
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<MDiv
					style={{
						position: "absolute",
						left: 0,
						height: IconContainerSize,
						paddingLeft: FUNCTIONAL_HAMBURGER_PADDING,
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<AnimatedHamburger
						testID="HamburgerInModal"
						isOpen={menuIsOpen}
						onPress={handleClose}
						size={FUNCTION_HAMBURGER_SIZE}
					/>
					{isLoading && (
						<OpacityView
							style={{
								marginLeft: paddingLarge,
							}}
						>
							<Loading />
						</OpacityView>
					)}
				</MDiv>
				<Link href="/home" onPress={handleClose}>
					<IconLogo size={NAV_BAR_LOGO_SIZE} />
				</Link>
			</MDiv>
			<AnimatedMenu routes={routes} onPress={startLoading} />
		</MDiv>
	);
};
export default HamburgerNavModal;
