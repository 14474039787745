import { StyleProp, View, ViewStyle } from "react-native";
import { paddingSmall } from "src/styles/spacing";
import { LabeledTextInputProps } from "../types";
import CharacterCounter from "./CharacterCounter";
import TextInputLabel from "./TextInputLabel";

const LabelHeaderRow = ({
	label,
	characterLimit,
	showCharacterLimit,
	value,
	style,
	required,
	sizeVariant,
}: { style?: StyleProp<ViewStyle> } & Pick<
	LabeledTextInputProps,
	"label" | "characterLimit" | "value" | "showCharacterLimit" | "required" | "sizeVariant"
>) => {
	return (
		<View
			style={[
				{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: paddingSmall,
				},
				style,
			]}
		>
			<TextInputLabel label={label} required={required} sizeVariant={sizeVariant} />
			{showCharacterLimit && characterLimit !== undefined && (
				<CharacterCounter characterLimit={characterLimit} value={value} />
			)}
		</View>
	);
};
export default LabelHeaderRow;
