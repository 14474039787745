import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { basicFont } from "src/styles/fonts";
import { paddingSmall } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import { LabeledTextInputProps } from "../types";

const CharacterCounter = ({
	characterLimit,
	value,
}: Pick<LabeledTextInputProps, "characterLimit" | "value">) => {
	const { theme } = useStyle();

	return (
		<Text
			testID="CharacterCounter"
			style={[
				basicFont,
				{
					fontSize: 12,
					color: color.TextLight[theme],
					marginTop: paddingSmall,
				},
				characterLimit !== undefined &&
					!!value &&
					value.length > characterLimit && {
						color: color.Error[theme],
					},
			]}
		>
			{value?.length ?? 0}/{characterLimit}
		</Text>
	);
};
export default CharacterCounter;
