import { ApolloClient } from "@apollo/client";
import {
	GatheringThumbnailReadUrlFragmentFragment,
	SignedUrl,
	Thumbnail,
} from "src/api/graphql/__generated__/graphql";
import { getClientId } from "src/helpers/globalHelpers";
import log from "src/helpers/log";

const persistOptimisticGraphQLBehavior = (
	apolloClient: ApolloClient<object>,
	image: {
		url: string | null;
		videoUrl: string | null;
		localImageUri: string | null;
		localVideoUri: string | null;
		optimisticOptimizedUrl: string | null;
		gatheringId: string | undefined;
		isThumbnail: boolean;
		photoId: string;
	},
) => {
	try {
		const {
			url,
			videoUrl,
			localImageUri,
			localVideoUri,
			optimisticOptimizedUrl,
			photoId,
			gatheringId,
			isThumbnail,
		} = image;

		// Be very careful with the optimistic behaviors you add here -- they can lead to rough performance issues
		// For example, updating the GraphQL cache's `numPhotos` for every single photo previously caused a re-render of GatheringHome for every photo
		if (gatheringId && isThumbnail) {
			apolloClient.cache.modify({
				id: apolloClient.cache.identify({
					__typename: "Gathering",
					gatheringId: gatheringId,
				}),
				fields: {
					thumbnail: () => {
						return {
							__typename: "Thumbnail",
							ownerId: getClientId(),
							photoId: photoId,
							readUrl: {
								__typename: "SignedUrl",
								url: url,
								stableUrl: optimisticOptimizedUrl ?? localImageUri,
								videoUrl: videoUrl,
								stableVideoUrl: localVideoUri,
								v0TemplateUrl: localImageUri,
							} satisfies GatheringThumbnailReadUrlFragmentFragment &
								Pick<
									SignedUrl,
									"url" | "stableUrl" | "videoUrl" | "stableVideoUrl"
								>,
						} satisfies Thumbnail;
					},
				},
			});
		}
	} catch (err) {
		log.error({ err }, "Error in persistOptimisticGraphQLBehavior");
	}
};
export default persistOptimisticGraphQLBehavior;
