import { track } from "@amplitude/analytics-react-native";
import { ApolloClient } from "@apollo/client";
import { amp } from "src/api/amplitude";
import { getAssetPersist } from "src/api/asset-persist/stores/useAssetPersist";
import { UploadAssetInfo } from "src/api/asset-persist/types";
import BatchGetOwnedPhotosForUploadCompletedDocument from "src/api/graphql/__generated__/documents/BatchGetOwnedPhotosForUploadCompletedDocument";
import GatheringWithPhotosFragmentFragmentDoc from "src/api/graphql/__generated__/documents/GatheringWithPhotosFragmentFragmentDoc";
import GatheringPhotosFilter from "src/api/graphql/__generated__/enums/GatheringPhotosFilter";
import PhotoState from "src/api/graphql/__generated__/enums/PhotoState";
import { BatchGetOwnedPhotosForUploadCompletedQuery } from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import { Timestamp_MS } from "src/shared/types/general";

const updateGatheringCache = (
	apolloClient: ApolloClient<object>,
	photos: NonNullable<BatchGetOwnedPhotosForUploadCompletedQuery["batchGetOwnedPhotos"]>,
	{ gatheringId, key }: { gatheringId: string; key?: string },
) => {
	const filters = [GatheringPhotosFilter.Active, GatheringPhotosFilter.AllCaller];
	for (const filter of filters) {
		apolloClient.cache.updateFragment(
			{
				fragment: GatheringWithPhotosFragmentFragmentDoc,
				fragmentName: "GatheringWithPhotosFragment",
				id: apolloClient.cache.identify({
					__typename: "Gathering",
					gatheringId,
				}),
				returnPartialData: true,
				variables: {
					photosInput: {
						// Matching `useGatheringPhotosWithLocal`
						filterUserIds: [],
						key,
						filter,
					},
				},
			},
			(gathering) => {
				// Just has an empty cache for gathering
				if (!gathering) return null;

				if (!gathering.photos) {
					// If you get this warning, there's a very good chance your keyArgs don't match (see filterUserIds above).
					// keyArgs should match with `useGatheringPhotosWithLocal`
					log.warn({ gatheringId }, "Missing photos field in gathering.");
					return;
				}
				return {
					...gathering,
					gatheringId,
					photos: {
						...gathering.photos,
						payload: [...(gathering.photos.payload ?? []), ...photos],
					},
				};
			},
		);
	}
};

const processCleanupUploadedPhotosBatch = async (
	uploadedPhotos: { photoId: string; localPhotoId: string }[],
	{
		apolloClient,
		imageLookup,
		delay,
	}: {
		apolloClient: ApolloClient<object>;
		imageLookup: Record<string, UploadAssetInfo>;
		delay?: Timestamp_MS;
	},
) => {
	if (uploadedPhotos.length === 0) return;
	try {
		if (delay) {
			await new Promise((resolve) => setTimeout(resolve, delay));
		}
		const { deleteUploadAsset, updateUploadAssets } = getAssetPersist();
		const { data } = await apolloClient.query({
			query: BatchGetOwnedPhotosForUploadCompletedDocument,
			variables: {
				input: {
					photoIds: uploadedPhotos.map((photo) => photo.photoId),
					allowedStates: [PhotoState.Active, PhotoState.Hidden, PhotoState.Errored],
				},
			},
			fetchPolicy: "network-only",
		});
		const verifiedUploadedPhotos: typeof data.batchGetOwnedPhotos = (
			data.batchGetOwnedPhotos ?? []
		).filter((photo) => photo.uploaded);
		const verifiedUploadedPhotoLookup: Record<
			string,
			NonNullable<typeof data.batchGetOwnedPhotos>[number]
		> = {};
		const groupedByGatheringId: Record<string, typeof verifiedUploadedPhotos> = {};
		for (const photo of verifiedUploadedPhotos) {
			verifiedUploadedPhotoLookup[photo.photoId] = photo;
			if (!photo.gatheringId) continue;
			if (!groupedByGatheringId[photo.gatheringId]) {
				groupedByGatheringId[photo.gatheringId] = [];
			}
			groupedByGatheringId[photo.gatheringId]?.push(photo);
		}
		for (const [gatheringId, photos] of Object.entries(groupedByGatheringId)) {
			updateGatheringCache(apolloClient, photos, { gatheringId });
			updateGatheringCache(apolloClient, photos, { gatheringId, key: "PhotoDetail" });
		}
		const numUploaded = verifiedUploadedPhotos.length;
		const numTotal = uploadedPhotos.length;
		const body = {
			numUploaded,
			numTotal,
			uploadedRatio: numUploaded / numTotal,
			isDelayed: !!delay,
			delay,
		};
		log.info(body, "Processed uploaded photos");
		// Use this to tune the delay on processUploadPhotosBatch
		track(amp.upload.confirmationBatch, body);

		const promises: Promise<void>[] = [];
		const updates: {
			localPhotoId: string;
			uploadState: "Processed" | "Confirming";
			stateUpdatedAt: Timestamp_MS;
		}[] = [];

		const cur_S = getCurTime_S();
		for (const photo of uploadedPhotos) {
			const foundPhoto = verifiedUploadedPhotoLookup[photo.photoId];
			if (foundPhoto && foundPhoto.uploaded) {
				const localPhotoId = foundPhoto.localPhotoId;
				if (!localPhotoId) continue;
				const localImageInfo = imageLookup[localPhotoId];
				if (!localImageInfo) continue;
				updates.push({
					localPhotoId,
					uploadState: "Processed",
					stateUpdatedAt: cur_S,
				});
				promises.push(
					new Promise((resolve) => setTimeout(resolve, 500)).then(async () => {
						await deleteUploadAsset(localPhotoId);
					}),
				);
			} else {
				const localPhotoId = photo.localPhotoId;
				if (!localPhotoId) continue;
				const localImageInfo = imageLookup[localPhotoId];
				if (!localImageInfo) continue;
				updates.push({
					localPhotoId,
					uploadState: "Confirming",
					stateUpdatedAt: cur_S,
				});
			}
		}
		updateUploadAssets(updates);
		await Promise.all(promises);
	} catch (err) {
		log.error({ err }, "Error in processCleanupUploadPhotosBatch");
	}
};

export default processCleanupUploadedPhotosBatch;
