import { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import MDiv from "src/swsh-native/MDiv";

const WebPopupBox = ({
	style,
	children,
	webTestID,
}: {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	webTestID?: string;
}) => {
	return (
		<MDiv
			testID={webTestID}
			initial={{
				scale: 0.5,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			exit={{
				scale: 0.5,
				opacity: 0,
			}}
			transition={{
				type: "spring",
				bounce: 0.35,
				duration: 0.5,
			}}
			style={style}
		>
			{children}
		</MDiv>
	);
};
export default WebPopupBox;
