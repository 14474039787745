import { FieldPolicy, Reference, StoreObject } from "@apollo/client";
import { Photo, PhotosCursor } from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import mergeCursor from "../helpers/mergeCursor";
import sortKeySortFn from "../helpers/sortKeySortFn";

const cacheGatheringHiddenPhotos: FieldPolicy<PhotosCursor | null> = {
	keyArgs: false,
	merge: (existing, incoming, { readField, mergeObjects }) => {
		if (incoming === null) {
			log.error("Null incoming photos");
		}
		const payloadLookup: Record<string, StoreObject | Reference> = {};
		const retObj = {
			cursor: mergeCursor(existing, incoming, {
				readField,
			}),
			payload: [] as Photo[],
		} satisfies PhotosCursor;
		for (const item of ([] as Photo[])
			.concat(incoming?.payload ?? [])
			.concat(existing?.payload ?? [])) {
			const photoId = readField<string>("photoId", item);
			if (!photoId) {
				// Likely means it was evicted from the cache
				continue;
			}
			const cur = payloadLookup[photoId];
			if (cur) {
				payloadLookup[photoId] = mergeObjects(cur, item);
			}
			payloadLookup[photoId] = item;
		}

		retObj.payload = Object.values(payloadLookup) as Photo[];
		retObj.payload.sort(
			sortKeySortFn(readField, {
				key: "sortKey",
			}),
		);
		return retObj;
	},
};
export default cacheGatheringHiddenPhotos;
