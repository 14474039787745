import { PopupInfo } from "../stores/usePopupStore";

const sortButtons = (buttons: PopupInfo["buttons"]) => {
	if (!buttons) return [];
	// Left to right: cancel, destructive, default
	return buttons.sort((a, b) => {
		if (a.style === "cancel") return -1;
		if (b.style === "cancel") return 1;
		if (a.style === "destructive") return -1;
		if (b.style === "destructive") return 1;
		return 0;
	});
};
export default sortButtons;
