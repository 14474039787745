import { flush, reset } from "@amplitude/analytics-react-native";
import type { ApolloClient } from "@apollo/client";
import useAssetPersist, { getAssetPersist } from "src/api/asset-persist/stores/useAssetPersist";
import { clearVariables as graphQLClearVariables } from "src/api/graphql/clearVariables";
import { grantedContactsVar } from "src/api/graphql/variables";
import mmkv from "src/api/mmkv";
import sentry from "src/api/sentry";
import log from "src/helpers/log";
import useGlobalStore, { getGlobalStore } from "src/stores/useGlobalStore";
import usePersistenceStore from "src/stores/usePersistenceStore";
import { getAuthStore } from "../stores/useAuthStore";
import cookieStore from "./cookieStore/cookieStore";

const wipeFileSystem = async () => {
	await getAssetPersist().clear();
};
const wipeAmplitude = async () => {
	await flush().promise;
	reset();
};

const clearState = async (apolloClient?: ApolloClient<object>) => {
	if (!apolloClient) {
		apolloClient = getGlobalStore().apolloClient ?? undefined;
	}

	sentry.setUser(null);
	grantedContactsVar(undefined);
	graphQLClearVariables();
	useAssetPersist.getState().clear();
	useAssetPersist.persist.clearStorage();
	mmkv.clearAll();

	useGlobalStore.getState().clear();
	useGlobalStore.persist.clearStorage();
	usePersistenceStore.getState().clear();
	usePersistenceStore.persist.clearStorage();
	getAuthStore().clear();

	await Promise.all([cookieStore.clearCookies(), wipeFileSystem(), wipeAmplitude()]).catch(
		(err) => {
			log.error({ err }, "Error clearing state");
		},
	);
	await apolloClient?.clearStore().catch((err) => {
		log.error({ err }, "Error clearing Apollo store");
	});
	apolloClient?.cache?.reset();

	mmkv.clearAll();
};
export default clearState;
