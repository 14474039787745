import { ApolloClient } from "@apollo/client";
import revalidateQueryCache from "src/api/graphql/variables/revalidateQueryCache";
import toast from "src/swsh-native/toast";
import sharedClearCache from "../sharedClearCache";

const handleClearCache = async (apolloClient: ApolloClient<any>) => {
	await sharedClearCache(apolloClient);
	revalidateQueryCache({});
	toast({ preset: "done", title: "Cache cleared!" });
};
export default handleClearCache;
