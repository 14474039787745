const parseRefCodeLink = ({
	link,
	split: splitKey,
}: {
	link: string;
	/**
	 * The string to split the link by
	 *
	 * album for gatherings
	 * group for communities
	 */
	split: string;
}) => {
	const refCode =
		link.startsWith("http://") || link.startsWith("https://") || link.includes("joinswsh.com")
			? // Grabs the part after group
				(() => {
					const split = link.split("/");
					const albumIndex = split.indexOf(splitKey);
					if (albumIndex === -1) return null;
					const refCode = split[albumIndex + 1];
					return refCode;
				})()
			: // Otherwise, just use the access code as is
				link;
	return refCode;
};
export default parseRefCodeLink;
