import { getAssetPersist } from "src/api/asset-persist/stores/useAssetPersist";
import { UploadAssetInfo } from "src/api/asset-persist/types";

const wipeChunk = async (chunk: UploadAssetInfo[]) => {
	const { updateUploadAsset, deleteUploadAsset } = getAssetPersist();

	for (const image of chunk) {
		updateUploadAsset({
			localPhotoId: image.localPhotoId,
			uploadState: "Failed",
		});
	}
	await new Promise((resolve) => setTimeout(resolve, 500));
	for (const image of chunk) {
		deleteUploadAsset(image.localPhotoId);
	}
};

export default wipeChunk;
