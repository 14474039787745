import { useEffect } from "react";
import useSplashScreenStore from "src/stores/useSplashScreenStore";

const AppReadyProvider = () => {
	const rootDidRender = useSplashScreenStore((state) => state.rootDidRender);
	// There's a web bug that can sometimes cause this to get stuck
	useEffect(() => {
		if (!rootDidRender) {
			useSplashScreenStore.setState({ rootDidRender: true });
		}
	}, [rootDidRender]);

	return null;
};
export default AppReadyProvider;
