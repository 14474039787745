import { track } from "@amplitude/analytics-react-native";
import { amp } from "src/api/amplitude";
import { UploadPhotosMutation } from "src/api/graphql/__generated__/graphql";
import log from "src/helpers/log";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import { getAssetPersist } from "../../../../stores/useAssetPersist";
import { UploadAssetInfo } from "../../../../types";
import writeAssetData from "./helpers/writeAssetData";

const uploadAsset = async (
	assetLookup: Record<string, UploadAssetInfo>,
	uploadPhotos: NonNullable<UploadPhotosMutation["uploadPhotos"]>[number],
): Promise<{
	success: boolean;
	didUpload: boolean;
}> => {
	const { deleteUploadAsset, updateUploadAsset } = getAssetPersist();
	try {
		const { imageWriteUrl, videoWriteUrl, localPhotoId, uploaded, requiredDataUploaded } =
			uploadPhotos;
		////////////////
		// Validation //
		////////////////
		if (!localPhotoId) {
			log.error("No localPhotoId");
			return {
				success: false,
				didUpload: false,
			};
		}
		if (uploaded || requiredDataUploaded) {
			return {
				success: true,
				didUpload: true,
			};
		}
		const asset = assetLookup[localPhotoId];
		if (!asset) {
			log.error("Could not find asset in lookup");
			return {
				success: false,
				didUpload: false,
			};
		}

		let success = true;
		try {
			await writeAssetData(asset, {
				url: imageWriteUrl?.url ?? undefined,
				videoUrl: videoWriteUrl?.url ?? undefined,
			});
			return {
				success: true,
				didUpload: true,
			};
		} catch (err) {
			if (err instanceof Error && err.message.includes("does not exist")) {
				log.error("Asset does not exist in expected uri location, deleting");
				await deleteUploadAsset(localPhotoId);
				return {
					success: false,
					didUpload: false,
				};
			} else {
				updateUploadAsset({
					localPhotoId: localPhotoId,
					uploadState: "Failed",
					stateUpdatedAt: getCurTime_S(),
				});
			}
			log.error({ err }, "Error uploading asset");
			throw err;
		} finally {
			track(amp.upload.dataUploadComplete, {
				success,
				gatheringId: asset?.gatheringId,
				variant: asset.uriInfo.variant,
			});
		}
	} catch (err) {
		log.error({ err }, "Error uploading asset");
		if (uploadPhotos?.localPhotoId) {
			updateUploadAsset({
				localPhotoId: uploadPhotos?.localPhotoId,
				uploadState: "Failed",
				stateUpdatedAt: getCurTime_S(),
			});
		}
		return {
			success: false,
			didUpload: false,
		};
	}
};

export default uploadAsset;
