import { SystemInfoInApp, SystemInfoOS } from "src/constants/SystemInfo/types";
import log from "../log";

const parseUserAgentForOS = (userAgent: string): SystemInfoOS => {
	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return "windows phone";
	} else if (/android/i.test(userAgent)) {
		return "android";
	} else if (/iPad|iPhone|iPod/.test(userAgent)) {
		return "ios";
	} else if (/Macintosh/.test(userAgent)) {
		return "macos";
	} else if (/Windows/.test(userAgent)) {
		return "windows";
	}
	// Linux must be after Android because Chrome on Android also contains "Linux"
	else if (/Linux/.test(userAgent)) {
		return "linux";
	}
	log.warn({ userAgent }, "Could not get OS from user agent");
	return "unknown";
};

const parseUserAgentForSubApp = (userAgent: string): SystemInfoInApp => {
	if (userAgent) {
		if (/Snapchat/.test(userAgent)) {
			return "Snapchat";
		}
		if (/Instagram/.test(userAgent)) {
			return "Instagram";
		}
	}
	return null;
};

const parseUserAgent = (
	userAgent?: string,
): {
	OS?: SystemInfoOS;
	inAppBrowser?: SystemInfoInApp;
} => {
	if (!userAgent) {
		return {};
	}
	return {
		OS: parseUserAgentForOS(userAgent),
		inAppBrowser: parseUserAgentForSubApp(userAgent),
	};
};

export default parseUserAgent;
