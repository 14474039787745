"use client";

import { memo, useEffect, useMemo, useRef } from "react";
import useStateRef from "src/hooks/useStateRef";
import AnimatePresence from "src/swsh-native/AnimatePresence";
import usePopupStore, { PopupInfo } from "../../stores/usePopupStore";
import WebPopup from "./components/WebPopup.web";

const WebPopupProvider = () => {
	const [activePopup, setActivePopup, activePopupRef] = useStateRef<PopupInfo | null>(null);
	const processPopups = useRef((Popups: PopupInfo[]) => {
		// Current active Popup is already in the queue
		if (
			activePopupRef.current &&
			Popups.some((Popup) => Popup.id === activePopupRef.current?.id)
		) {
			return;
		}
		const newPopup = Popups[0];
		if (newPopup) {
			setActivePopup(newPopup);
		} else if (activePopupRef.current) {
			setActivePopup(null);
		}
	});

	// useMemo instead of useEffect here since we want to capture the initial render
	useMemo(() => {
		usePopupStore.getState().clear();
	}, []);

	useEffect(() => {
		const initialPopups = usePopupStore.getState().popups;
		processPopups.current(initialPopups);
		const unsubscribe = usePopupStore.subscribe(
			(state) => state.popups,
			(Popups) => {
				processPopups.current(Popups);
			},
		);
		return () => {
			unsubscribe();
		};
	}, []);

	return <AnimatePresence>{activePopup && <WebPopup popup={activePopup} />}</AnimatePresence>;
};
export default memo(WebPopupProvider);
