"use client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { ReactNode } from "react";
import configureAmplitude from "src/api/amplitude/configureAmplitude";
import { curEnv, isDev } from "src/config";
import { consoleDev } from "src/helpers/devHelpers";
import log from "src/helpers/log";
import { GlobalModalConfig } from "src/modals/configs/GlobalModal";
import RootModalContainer from "src/modals/containers/RootModalContainer";
import Fatal from "src/screens/(error)/Fatal";
import SplashScreen from "src/screens/SplashScreen";
import AppReadyProvider from "./AppReadyProvider";
import AppStateProvider from "./AppStateProvider";
import ConfiguredStatusBar from "./components/ConfiguredStatusBar";
import GraphQLProvider from "./GraphQLProvider";
import InternalProvider from "./InternalProvider";
import NavigationProvider from "./NavigationProvider";
import PackageProvider from "./PackageProvider";
import SyncImages from "./SyncImages";

var initError: any = null;

try {
	configureAmplitude();

	if (isDev) {
		loadDevMessages();
		loadErrorMessages();
	}
} catch (err) {
	initError = err;
}

const Root = ({ children }: { children: ReactNode }) => {
	consoleDev("Initializing...");
	if (initError) {
		log.error({ err: initError }, "Error initializing");
	}

	// Killing on fatal errors
	// Keep this below the updateEAS above, in hopes of recovery
	if (curEnv === "missing") {
		log.error(
			"Missing BRANCH variable. Check eas.json. If you see this error, contact Nathan immediately",
		);
		return <Fatal />;
	}

	try {
		return (
			<>
				<NavigationProvider>
					<PackageProvider>
						{/* Takes some amount of time to load Apollo Client. Not using promises because we want to load a new client under RootReload */}
						<GraphQLProvider>
							<AppStateProvider>
								{children}
								<AppReadyProvider />
								<InternalProvider />
								<RootModalContainer config={GlobalModalConfig} />
								<SyncImages />
								<ConfiguredStatusBar />
							</AppStateProvider>
						</GraphQLProvider>
					</PackageProvider>
				</NavigationProvider>
				<SplashScreen />
			</>
		);
	} catch (err) {
		throw err;
	}
};

export default Root;
