import { ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { consoleDevDuration } from "src/helpers/devHelpers";

const loggingMiddleware = new ApolloLink((operation, forward) => {
	let t = performance.now();
	return forward(operation).map((response) => {
		const definition = getMainDefinition(operation.query);
		if (definition.kind === "OperationDefinition" && definition.operation !== "subscription") {
			let diff = Math.round(performance.now() - t);
			consoleDevDuration(operation.operationName, diff);
		}
		return response;
	});
});

export default loggingMiddleware;
